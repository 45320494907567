import { publicHttp, secretHttp } from './http'
import stats from './stats'

export default {
  ...stats,
  getChains: () => publicHttp.get<ChainConfigItem[]>('/chains'),
  getAssetPrice: () => publicHttp.get<AssetPriceItem[]>('/asset/usePric es'),
  getAllAssetList: () => publicHttp.get<AssetItem[]>('/asset/assets'),
  getAllSymbolList: () => publicHttp.get<SymbolItem[]>('/spot/symbols'),
  getFeeList: () => publicHttp.get<FeeItem[]>('/feeLevels'),
  getKLine: (params: KLineReq) => publicHttp.get<KLineItem[]>('/spot/klines', { params }),
  getUserRegisterChain: (address: string) => publicHttp.get<{ exist: boolean, chainId: string | null }>(`/users/address/${address}/chain`),
  getPoolList: () => publicHttp.get<PoolItem[]>('/amm/pools'),
  getPoolSummaryList: () => publicHttp.get<PoolSummaryResponse>('/amm/pools'),

  createUser: (data: CreateUserRequest) => publicHttp.post<A<CreateUserResponse>>('/users', data),
  getUserInfo: () => secretHttp.get<UserInfo>('/profile'),
  getUserAssets: () => secretHttp.get<UserAsset[]>('/asset/assets'),
  getAdminSignature: (chainId: number) => secretHttp.get<AdminSignature>('/registerSignature', { params: { chainId } }),
  getUserActionHistory: () => secretHttp.get<UserActionHistory[]>('/asset/actionHistory'),

  // order
  placeOrder: (data: PlaceOrderRequest) => secretHttp.post('/spot/order', data),

  // spot
  getAvgCost: () => secretHttp.get<AvgCostItem[]>('/asset/avgCost'),
  getStartBalance: () => http.get<StartBalanceItem[]>('/asset/balanceStartOfDay'),
  // getSpotHistory: (params: { periods: number }) => http.get<A<{ history: SpotHistory[] }>>('/gateway/user/balance/history', { params }),

  // faucet
  addMint: (data: AddMintItem) => secretHttp.post('/asset/mint/record', data),
  getMintedList: () => secretHttp.get<MinedItem[]>('/asset/mint/records'),
}
