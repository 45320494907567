<script setup lang="tsx">
import BN from 'bignumber.js'
import VIcon from '@/components/v/Icon.vue'
import VTickSpacing from '@/components/v/TickSpacing.vue'

const route = useRoute()
const router = useRouter()

const { poolDetailList, updatePool } = useAmm()
const { symbolList, currencyList, tickerInfo } = useSymbol()
const { symbolPrice, assetBalance } = useWs()
const { run: addPool, isLoading: isLoadingAdd } = useHttp(vesselApi.amm.addPool)

const showPreview = ref(false)
const isTickTooSmall = ref(false)
const selectedPriceRange = ref()
const hasInit = ref(false)
const isAdding = ref(false)

const currencyDirection = ref('')
const lastEditAmount = ref<'base' | 'quote'>('base')

const notification = useNotification()

const form = ref<{
  poolId: string
  symbol: string
  min: string
  max: string
  minIndex: number
  maxIndex: number
  baseAmount: string
  quoteAmount: string
}>({
  poolId: null,
  symbol: '',
  minIndex: 0,
  maxIndex: 0,
  min: '',
  max: '',
  baseAmount: '',
  quoteAmount: '',
})

function back() {
  if (history.state.back) {
    router.back()
  }
  else {
    router.replace('/pools')
  }
}

const currentSymbolItem = computed(() => poolDetailList.value.find(i => i.poolId === form.value.poolId))
const currentTickerItem = computed(() => tickerInfo.value.find(i => i.symbol === currentSymbolItem.value?.symbol))
const currencyPair = computed(() => {
  return getSymbolItem(currentSymbolItem.value?.symbol)
})

const currentPairSwitch = computed(() => {
  if (currencyPair.value?.base) {
    return [currencyPair.value.base, currencyPair.value.quote]
  }
  else {
    return ['base', 'quote']
  }
})

const priceRangeList = computed(() => {
  const priceRangeString = currentSymbolItem.value?.priceRange || '0.05,0.1,0.25,0.5'
  return priceRangeString.split(',').map(i => +i)
})

const isQuote = computed(() => currencyDirection.value === currencyPair.value?.base)
const priceSuffix = computed(() => isQuote.value
  ? `${currencyPair.value?.quote} per ${currencyPair.value?.base}`
  : `${currencyPair.value?.base} per ${currencyPair.value?.quote}`,
)
const currentPrice = computed(() => {
  return symbolPrice.value?.[currentSymbolItem.value?.symbol]?.closePrice || '0'
})
const currentPriceIndex = computed(() => getSymbolIndex(currentPrice.value))

function getAvailableBalance(assetName: string) {
  return +assetBalance.value?.find(i => i.assetName === assetName)?.available || 0
}

function init() {
  if (poolDetailList.value.length && Object.keys(symbolPrice.value).length && !hasInit.value) {
    const symbol = route.query.symbol as string
    const ts = route.query.ts as string
    const defaultPoolId = poolDetailList.value?.[0]?.poolId
    if (symbol && ts) {
      form.value.poolId = poolDetailList.value.find(i => i.symbol === symbol && i.tickSpacing === ts)?.poolId || defaultPoolId
    }
    else if (symbol) {
      form.value.poolId = poolDetailList.value
        .sort((i, j) => +i.tickSpacing - +j.tickSpacing)
        .find(i => i.symbol === symbol)?.poolId || defaultPoolId
    }
    else {
      form.value.poolId = defaultPoolId
    }
    handleChangePool(form.value.poolId)
    currencyDirection.value = getSymbolItem(poolDetailList.value.find(i => i.poolId === form.value.poolId)?.symbol)?.base
    hasInit.value = true
  }
}

const isInsufficientBase = computed(() => +form.value.baseAmount > getAvailableBalance(currencyPair.value?.base))
const isInsufficientQuote = computed(() => +form.value.quoteAmount > getAvailableBalance(currencyPair.value?.quote))

const isInsufficient = computed(() => {
  if (form.value.minIndex < currentPriceIndex.value && currentPriceIndex.value < form.value.maxIndex) {
    return isInsufficientBase.value || isInsufficientQuote.value
  }
  if (form.value.minIndex > currentPriceIndex.value) {
    return isInsufficientBase.value
  }
  if (form.value.maxIndex < currentPriceIndex.value) {
    return isInsufficientQuote.value
  }
  return false
})

const isOutOfRange = computed(() => (form.value.maxIndex && form.value.maxIndex <= currentPriceIndex.value)
  || (form.value.minIndex && form.value.minIndex >= currentPriceIndex.value))

const isMinHigherThanMin = computed<boolean>(() => form.value.min && form.value.max && +form.value.min >= +form.value.max || false)

const isDisablePreview = computed(() => {
  return isMinHigherThanMin.value || !form.value.baseAmount || !form.value.quoteAmount || isInsufficient.value || isTickTooSmall.value
})

function onPreview() {
  if (!form.value.min || !form.value.max || +form.value.min >= +form.value.max || isDisablePreview.value) {
    return
  }
  showPreview.value = true
}

function getSymbolIndex(value: string) {
  if (!value) {
    return 0
  }
  const currentSymbolAmmItem = currentSymbolItem.value
  if (+value < +currentSymbolAmmItem?.minTick) {
    return 0
  }
  if (+value > +currentSymbolAmmItem?.maxTick) {
    return Math.round((+currentSymbolAmmItem.maxTick - +currentSymbolAmmItem.minTick) / +currentSymbolAmmItem.tickSpacing)
  }
  return Math.round((+value - +currentSymbolAmmItem?.minTick) / +currentSymbolAmmItem?.tickSpacing)
}

function getSymbolValueFromIndex(index: number) {
  const currentSymbolAmmItem = currentSymbolItem.value
  if (!currentSymbolAmmItem?.tickSpacing) {
    return ''
  }
  return BN(currentSymbolAmmItem?.minTick).plus(BN(currentSymbolAmmItem?.tickSpacing).multipliedBy(BN(index))).toString()
}

const minBaseAmount = computed(() => {
  if (currentPriceIndex.value > form.value.minIndex) {
    return (form.value.maxIndex - currentPriceIndex.value + 1) / 10 ** +currentTickerItem.value.baseAssetPrecision
  }
  return (form.value.maxIndex - form.value.minIndex + 1) / 10 ** +currentTickerItem.value.baseAssetPrecision
})
const minQuoteAmount = computed(() => {
  if (currentPriceIndex.value < form.value.maxIndex) {
    return (currentPriceIndex.value - form.value.minIndex + 1) / 10 ** +currentTickerItem.value.baseAssetPrecision
      * +getSymbolValueFromIndex((+form.value.minIndex + currentPriceIndex.value) / 2)
  }
  return (form.value.maxIndex - form.value.minIndex + 1) * +getSymbolValueFromIndex((+form.value.minIndex + currentPriceIndex.value) / 2) / 10 ** +currentTickerItem.value.baseAssetPrecision
})

function onChangeDirection() {
  if (isQuote.value) {
    form.value.max = getSymbolValueFromIndex(form.value.maxIndex)
    form.value.min = getSymbolValueFromIndex(form.value.minIndex)
  }
  else {
    form.value.min = BN(1).dividedBy(BN(getSymbolValueFromIndex(form.value.maxIndex))).toFixed(Math.max(0, 12 - getPrecisionFromSymbol(currentSymbolItem.value.symbol).price)).toString()
    form.value.max = BN(1).dividedBy(BN(getSymbolValueFromIndex(form.value.minIndex))).toFixed(Math.max(0, 12 - getPrecisionFromSymbol(currentSymbolItem.value.symbol).price)).toString()
  }
}

function handleChangePool(poolId: string) {
  // remove all inputs
  const poolItem = poolDetailList.value.find(i => i.poolId === poolId)
  const symbol = poolItem?.symbol
  const p = symbolPrice.value?.[symbol]?.closePrice
  const rate = +poolItem.priceRange?.split(',')?.[0] || 0.05
  selectedPriceRange.value = rate
  nextTick(() => {
    form.value.symbol = symbol
    form.value.baseAmount = ''
    form.value.quoteAmount = ''
    form.value.min = getSymbolValueFromIndex(getSymbolIndex(`${+p * (1 - rate)}`))
    form.value.max = getSymbolValueFromIndex(getSymbolIndex(`${+p * (1 + rate)}`))
    form.value.minIndex = getSymbolIndex(`${+p * (1 - rate)}`)
    form.value.maxIndex = getSymbolIndex(`${+p * (1 + rate)}`)
    currencyDirection.value = getSymbolItem(symbol)?.base
    // isMinHigherThanMin.value = +form.value.min >= +form.value.max
  })
}

function onSelectPriceRange(rate: number) {
  const p = symbolPrice.value?.[currentSymbolItem.value?.symbol]?.closePrice
  selectedPriceRange.value = rate
  setTimeout(() => {
    form.value.min = getSymbolValueFromIndex(getSymbolIndex(`${+p * (1 - rate)}`))
    form.value.max = getSymbolValueFromIndex(getSymbolIndex(`${+p * (1 + rate)}`))
    form.value.minIndex = getSymbolIndex(`${+p * (1 - rate)}`)
    form.value.maxIndex = getSymbolIndex(`${+p * (1 + rate)}`)
    if (currentPriceIndex.value <= form.value.minIndex) {
      isTickTooSmall.value = minBaseAmount.value > +form.value.baseAmount
    }
    else if (currentPriceIndex.value >= form.value.maxIndex) {
      isTickTooSmall.value = minQuoteAmount.value > +form.value.quoteAmount
    }
    else if (form.value.minIndex < currentPriceIndex.value && currentPriceIndex.value < form.value.maxIndex) {
      isTickTooSmall.value = minBaseAmount.value > +form.value.baseAmount || minQuoteAmount.value > +form.value.quoteAmount
    }
    // isMinHigherThanMin.value = +form.value.min >= +form.value.max
  }, 50)
}
function changeAmount() {
  form.value.baseAmount = form.value.baseAmount.replace(/^([^.]*)\.([^.]*)\.?/, '\$1.\$2')
  form.value.quoteAmount = form.value.quoteAmount.replace(/^([^.]*)\.([^.]*)\.?/, '\$1.\$2')

  if (form.value.minIndex && form.value.maxIndex && form.value.minIndex >= currentPriceIndex.value) {
    form.value.quoteAmount = '0'
  }
  if (form.value.minIndex && form.value.maxIndex && form.value.maxIndex <= currentPriceIndex.value) {
    form.value.baseAmount = '0'
  }

  if (currentPriceIndex.value <= form.value.minIndex) {
    isTickTooSmall.value = minBaseAmount.value > +form.value.baseAmount
  }
  else if (currentPriceIndex.value >= form.value.maxIndex) {
    isTickTooSmall.value = minQuoteAmount.value > +form.value.quoteAmount
  }

  if (isOutOfRange.value) {
    return
  }

  if (lastEditAmount.value === 'base') {
    // const L0 = currentSymbolItem.value.priceTickLeftLiquidity
    // const L = currentSymbolItem.value.priceTickLiquidity
    const p = +getSymbolValueFromIndex(currentPriceIndex.value)
    const A = +getSymbolValueFromIndex(form.value.minIndex)
    const B = +getSymbolValueFromIndex(form.value.maxIndex)
    const y = +form.value.baseAmount
    const t = +currentSymbolItem.value.tickSpacing
    // console.log(A, B, p, y, t)

    const r = 0.5
    const s = y / ((B - p - t) / t + r)
    const x = s * ((p - A) / t) * ((p - t + A) / 2) + s * (1 - r) * p
    // console.log(r, s, x)

    form.value.quoteAmount = Number.isNaN(x) ? '' : `${floorTo(x, +currentTickerItem.value?.quoteAssetPrecision)}`
  }
  else {
    // const L0 = currentSymbolItem.value.priceTickLeftLiquidity
    // const L = currentSymbolItem.value.priceTickLiquidity
    const p = +getSymbolValueFromIndex(currentPriceIndex.value)
    const A = +getSymbolValueFromIndex(form.value.minIndex)
    const B = +getSymbolValueFromIndex(form.value.maxIndex)
    const x = +form.value.quoteAmount
    const t = +currentSymbolItem.value.tickSpacing
    // console.log(A, B, p, x, t, L, L0)

    const l = 0.5
    const s = x / (p * l + (p - A) / t * (p - t + A) / 2)
    const y = ((B - p - t) / t + 1 - l) * s
    // console.log(l, s, y)

    form.value.baseAmount = Number.isNaN(y) ? '' : `${floorTo(y, +currentTickerItem.value?.baseAssetPrecision)}`
  }

  if (form.value.minIndex < currentPriceIndex.value && currentPriceIndex.value < form.value.maxIndex) {
    isTickTooSmall.value = minBaseAmount.value > +form.value.baseAmount || minQuoteAmount.value > +form.value.quoteAmount
  }
}

function onBlurMin() {
  if (Number.isNaN(form.value.min)) {
    return
  }
  if (isQuote.value) {
    // no need convert
    const realIndex = getSymbolIndex(form.value.min)
    form.value.minIndex = realIndex
    form.value.min = getSymbolValueFromIndex(realIndex)
  }
  else {
    const realIndex = getSymbolIndex(BN(1).dividedBy(BN(form.value.min)).toString())
    form.value.maxIndex = realIndex
    form.value.min = BN(1).dividedBy(BN(getSymbolValueFromIndex(realIndex))).toFixed(Math.max(0, 12 - getPrecisionFromSymbol(currentSymbolItem.value.symbol).price)).toString()
  }
  // if (form.value.min && form.value.max && +form.value.min >= +form.value.max) {
  //   isMinHigherThanMin.value = true
  // }
  if (form.value.maxIndex <= currentPriceIndex.value) {
    form.value.baseAmount = '0'
  }
  if (form.value.minIndex >= currentPriceIndex.value) {
    form.value.quoteAmount = '0'
  }
  changeAmount()
  selectedPriceRange.value = null
}
function onBlurMax() {
  if (Number.isNaN(form.value.max)) {
    return
  }
  if (isQuote.value) {
    // no need convert
    const realIndex = getSymbolIndex(form.value.max || `${Number.MAX_SAFE_INTEGER}`)
    form.value.maxIndex = realIndex
    form.value.max = getSymbolValueFromIndex(realIndex)
  }
  else {
    const realIndex = getSymbolIndex(BN(1).dividedBy(BN(form.value.max)).toString())
    form.value.minIndex = realIndex
    form.value.max = BN(1).dividedBy(BN(getSymbolValueFromIndex(realIndex))).toFixed(Math.max(0, 12 - getPrecisionFromSymbol(currentSymbolItem.value.symbol).price)).toString()
  }

  // if (form.value.min && form.value.max && +form.value.min >= +form.value.max) {
  //   isMinHigherThanMin.value = true
  // }
  if (form.value.maxIndex <= currentPriceIndex.value) {
    form.value.baseAmount = '0'
  }
  if (form.value.minIndex >= currentPriceIndex.value) {
    form.value.quoteAmount = '0'
  }
  changeAmount()
  selectedPriceRange.value = null
}

function getPrice(amount: string, assetName: string) {
  const price = currencyList.value.find(i => i.assetName === assetName)?.price || 0

  return `$${BN(amount || 0).multipliedBy(BN(price)).toFixed(2)}`
}

function onMax(type: 'base' | 'quote') {
  lastEditAmount.value = type
  form.value[`${type}Amount`] = `${getAvailableBalance(currencyPair.value[type])}`
  changeAmount()
}

function onChangeAmount(type: 'base' | 'quote') {
  lastEditAmount.value = type
  changeAmount()
}

async function addLiquidity() {
  if (isAdding.value) {
    return
  }
  isAdding.value = true
  // add
  const signMessage = {
    poolId: +currentSymbolItem.value.poolId,
    addType: 1,
    tickIndexL: form.value.minIndex,
    tickIndexR: form.value.maxIndex,
    baseAmount: Math.floor(+form.value.baseAmount * 10 ** +currentTickerItem.value.baseAssetPrecision) / 10 ** +currentTickerItem.value.baseAssetPrecision,
    quoteAmount: Math.floor(+form.value.quoteAmount * 10 ** +currentTickerItem.value.quoteAssetPrecision) / 10 ** +currentTickerItem.value.quoteAssetPrecision,
    timestamp: new Date().getTime(),
    // timestamp: 1710195590372,
    // nonce: 72,
    nonce: new Date().getTime(),
  }
  const signature = await signPool(signMessage, form.value.symbol)

  addPool({
    ...signMessage,
    signature,
  }, {
    headers: {
      'VESSEL-TIMESTAMP': signMessage.timestamp,
    },
  }).then((res) => {
    notification.success({
      title: 'Add Liquidity Confirmation',
      content: `Your liquidity of ${res.data.baseAssetAmount} ${currencyPair.value.base} and ${res.data.quoteAssetAmount} ${currencyPair.value.quote} has been successfully added to the ${currencyPair.value.base}/${currencyPair.value.quote} pool.`,
      duration: 2000,
    })
    showPreview.value = false
    isAdding.value = false
    router.back()
  })
}

function onChangePriceInChart({ left, right }: { left: number, right: number }) {
  form.value.min = left.toString()
  form.value.max = right.toString()
  onBlurMin()
  onBlurMax()
}

function renderLabel(option: any) {
  const c = getSymbolItem(option.symbol)

  return (
    <div class="flex items-center justify-start">
      <VIcon currency={c?.base} class="mr-0.024 h-0.24 w-0.24" />
      <VIcon currency={c?.quote} class="mr-0.08 h-0.24 w-0.24" />
      <span class="mr-0.08">
        {c?.base}
        /
        {c?.quote}
      </span>
      <VTickSpacing tickSpacing={option.tickSpacing} token={c?.quote} />
    </div>
  )
}

watch([currentPrice, symbolPrice, poolDetailList], () => {
  init()
}, {
  immediate: true,
})
onMounted(() => {
  updatePool()
})
</script>

<template>
  <div class="mx-auto w-6.22">
    <v-back name="Back" @click="back" />
    <div class="mt-0.12 text-0.32 font-700 font-dm">
      Add Liquidity
    </div>
    <div class="mb-0.14 mt-0.08 text-caption2b text-grey1">
      Add liquidity to create position. The position value might vary from input due to price impact.
    </div>
    <div class="mb-0.14 text-grey2">
      Select Pool
    </div>
    <n-select
      v-model:value="form.poolId"
      :render-label="renderLabel"
      :options="poolDetailList" filterable
      :filter="(pattern, option) => {
        const c = getSymbolItem(option.symbol as string)
        return `${c.base}${c.quote}`.toLowerCase().includes(pattern.replace(/\//g, '').toLowerCase())
      }"
      label-field="symbol" value-field="poolId"
      @update-value="handleChangePool"
    />
    <div class="my-0.14 flex items-center justify-between text-grey2">
      <div class="flex gap-x-0.04">
        <div>Select Price Range</div>
        <v-tag
          v-for="(item, index) in priceRangeList"
          :key="index" class="cursor-pointer" :label="getRatio(item, -1)"
          :selected="selectedPriceRange === item" @click="onSelectPriceRange(item)"
        />
      </div>
      <v-switch v-model="currencyDirection" :options="currentPairSwitch" @change="onChangeDirection" />
    </div>

    <v-input-amm
      v-model="form.min" class="mb-0.14" prefix-top="Min Price" :suffix="priceSuffix"
      :error-message="isMinHigherThanMin ? '' : undefined"
      @input="isMinHigherThanMin = false"
      @blur="onBlurMin"
    />
    <v-input-amm
      v-model="form.max" prefix-top="Max Price" :suffix="priceSuffix"
      :error-message="isMinHigherThanMin ? 'Invalid price range. The min price must be lower than the max price.' : undefined"
      @input="isMinHigherThanMin = false"
      @blur="onBlurMax"
    />
    <div
      v-if="isOutOfRange"
      class="mt-0.14 flex items-start text-0.12 text-grey1 font-600"
    >
      <SvgWarning class="mr-0.04" />
      Out of range. Your position won't earn fees or participate in trades until the market price move into your specified range.
    </div>

    <div class="mt-0.14 text-grey2">
      Current Price
    </div>
    <div v-if="isQuote" class="text-white1">
      1 {{ currencyPair?.base }} = {{ formatNumber(currentPrice, +currentTickerItem?.quoteAssetPrecision - +currentTickerItem?.baseAssetPrecision) }} {{ currencyPair?.quote }}
    </div>
    <div v-else>
      1 {{ currencyPair?.quote }} = {{ formatNumber(1 / (+currentPrice || 1), 8) }} {{ currencyPair?.base }}
    </div>
    <v-pool-price-range
      :current-price="currentPrice"
      class="mt-0.14"
      :pool-id="form.poolId"
      :initial-min="+form.min"
      :initial-max="+form.max"
      :is-quote="isQuote"
      @change="onChangePriceInChart"
    />
    <div class="my-0.14 text-grey2">
      <div>Amount</div>
    </div>

    <v-input-amm
      v-model="form.baseAmount" class="mb-0.14"
      :prefix-bottom="getPrice(form.baseAmount, currencyPair?.base)"
      :locked="!!form.maxIndex && form.maxIndex <= currentPriceIndex"
      :error-message="isInsufficientBase ? '' : undefined"
      @change="onChangeAmount('base')"
    >
      <template #suffix>
        <div class="flex flex-col items-end">
          <div class="flex items-center gap-x-0.08">
            <VIcon :currency="currencyPair?.base" class="w-0.16" />
            {{ currencyPair?.base }}
          </div>
          <div class="mt-0.06 flex text-0.12">
            <span class="mr-0.08 text-grey1 font-600">Available</span>
            {{ getAvailableBalance(currencyPair?.base) }} {{ currencyPair?.base }}
            <v-button size="tiny" class="ml-0.08 h-0.2!" bg="black-3" color="primary" @click="onMax('base')">
              Max
            </v-button>
          </div>
        </div>
      </template>
    </v-input-amm>
    <v-input-amm
      v-model="form.quoteAmount" class="mb-0.14"
      :prefix-bottom="getPrice(form.quoteAmount, currencyPair?.quote)"
      :locked="!!form.minIndex && form.minIndex >= currentPriceIndex"
      :error-message="isInsufficientQuote ? '' : undefined"
      @change="onChangeAmount('quote')"
    >
      <template #suffix>
        <div class="flex flex-col items-end">
          <div class="flex items-center gap-x-0.08">
            <VIcon :currency="currencyPair?.quote" class="w-0.16" />
            {{ currencyPair?.quote }}
          </div>
          <div class="mt-0.06 flex text-0.12">
            <span class="mr-0.08 text-grey1 font-600">Available</span>
            {{ getAvailableBalance(currencyPair?.quote) }} {{ currencyPair?.quote }}
            <v-button size="tiny" class="ml-0.08 h-0.2!" bg="black-3" color="primary" @click="onMax('quote')">
              Max
            </v-button>
          </div>
        </div>
      </template>
    </v-input-amm>
    <div v-if="isInsufficientBase || isInsufficientQuote" class="mt--0.08 text-0.14 color-red font-500">
      Insufficient Balance.
    </div>
    <!-- <div
      v-if="isOutOfRange"
      class="flex items-start text-0.12 font-600 text-grey1"
    >
      <SvgWarning class="mr-0.04" />
      The market price is out of your specified price range. Single-asset deposit only.
    </div> -->

    <div v-else-if="isTickTooSmall" class="mt--0.08 text-0.14 color-red font-500">
      <div v-if="!!form.maxIndex && form.maxIndex <= currentPriceIndex">
        You must at least add {{ formatNumber(minQuoteAmount, getPrecisionFromSymbol(currentSymbolItem?.symbol).quote) }} {{ currencyPair.quote }} to this price range.
      </div>
      <div v-else-if="!!form.minIndex && form.minIndex >= currentPriceIndex">
        You must at least add {{ formatNumber(minBaseAmount, getPrecisionFromSymbol(currentSymbolItem?.symbol).base) }} {{ currencyPair.base }} to this price range.
      </div>
      <div v-else>
        You must at least add {{ formatNumber(minBaseAmount, getPrecisionFromSymbol(currentSymbolItem?.symbol).base) }} {{ currencyPair.base }} and {{ formatNumber(minQuoteAmount, getPrecisionFromSymbol(currentSymbolItem.symbol).quote) }} {{ currencyPair.quote }} to this price range.
      </div>
    </div>

    <v-button class="mb-0.32 mt-0.32 w-full" type="primary" :disabled="isDisablePreview" @click="onPreview">
      Preview
    </v-button>

    <v-modal
      v-model:show="showPreview"
      modal-class="w-5.5 text-grey1"
      :z-index="201"
      title="Add Liquidity"
    >
      <div class="mt-0.2 font-500">
        <div class="flex justify-between">
          <div class="flex items-center gap-x-0.08 text-white2">
            <v-icon-pair :base="currencyPair.base" :quote="currencyPair.quote" :size="24" />
            {{ currencyPair.base }}/{{ currencyPair.quote }}
            <VTickSpacing :tick-spacing="currentSymbolItem.tickSpacing" :token="currencyPair.quote" />
          </div>
          <div v-if="isOutOfRange" class="flex items-center text-0.14 text-yellow font-700 font-dm">
            <div class="mr-.04 h-0.06 w-0.06 rd-full bg-yellow pt-0.06" />
            Out of Range
          </div>
          <div v-else class="flex items-center text-0.14 text-green font-700 font-dm">
            <div class="mr-.04 h-0.06 w-0.06 rd-full bg-green pt-0.06" />
            In Range
          </div>
        </div>
        <div class="mt-0.12 rd-0.12 bg-black3 p-0.12">
          <div class="flex justify-between">
            <div class="flex items-center text-white2">
              <VIcon :currency="currencyPair.base" class="mr-0.04 w-0.24" />
              {{ currencyPair.base }}
            </div>
            <div>
              <span class="mr-0.04 text-white2">{{ form.baseAmount }}</span>
              <span class="text-0.14 text-grey2">{{ getPrice(form.baseAmount, currencyPair?.base) }}</span>
            </div>
          </div>
          <div class="mt-0.24 flex justify-between">
            <div class="flex items-center text-white2">
              <VIcon :currency="currencyPair.quote" class="mr-0.04 w-0.24" />
              {{ currencyPair.quote }}
            </div>
            <div>
              <span class="mr-0.04 text-white2">{{ form.quoteAmount }}</span>
              <span class="text-0.14 text-grey2">{{ getPrice(form.quoteAmount, currencyPair?.quote) }}</span>
            </div>
          </div>
        </div>
        <div class="my-0.14 flex items-center justify-between text-grey2">
          <div>Price Range</div>
          <v-switch v-model="currencyDirection" disabled :options="currentPairSwitch" />
        </div>
        <div class="my-0.12 flex items-center">
          <div class="mr-0.04 text-grey2 font-400">
            Current Price
          </div>
          <div v-if="isQuote" class="text-white1">
            1 {{ currencyPair?.base }} = {{ formatNumber(currentPrice, +currentTickerItem?.quoteAssetPrecision - +currentTickerItem?.baseAssetPrecision) }} {{ currencyPair?.quote }}
          </div>
          <div v-else>
            1 {{ currencyPair?.quote }} = {{ formatNumber(1 / (+currentPrice || 1), 8) }} {{ currencyPair?.base }}
          </div>
        </div>
        <div class="mt-0.12 rd-0.12 bg-black3 p-0.12">
          <div class="flex justify-between">
            <div class="flex items-center text-0.14 text-white2">
              Min Price
            </div>
            <div class="text-white2">
              {{ formatNumber(form.min, -1) }} {{ priceSuffix }}
            </div>
          </div>
          <div class="mt-0.24 flex justify-between">
            <div class="flex items-center text-0.14 text-white2">
              Max Price
            </div>
            <div class="text-white2">
              {{ formatNumber(form.max, -1) }} {{ priceSuffix }}
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="mt-0.32 flex gap-x-0.16">
          <v-button class="flex-1" :loading="isAdding" @click="addLiquidity">
            Add Liquidity
          </v-button>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<style scoped>

</style>
