<script setup lang="ts">
onMounted(() => {
  vesselApiServer.getChains().then((res) => {
    console.log(res)
  })
})
</script>

<template>
  <div>
    foo
  </div>
</template>

<style scoped>

</style>
