<script lang="tsx" setup>
import { useRouteQuery } from '@vueuse/router'
import type { SelectOption } from 'naive-ui'
import { getBalance as _getBalance, waitForTransactionReceipt } from '@wagmi/core'
import { formatEther, formatUnits, hexToNumber, parseUnits } from 'viem'
import VIcon from '@/components/v/Icon.vue'
import { getWagmiConfig } from '~/lib/wagmi'

const { checkChain, currentChainId } = useConnector()
const { address, userInfo } = useUser()
const { vesselKey } = useKey()
const { assetList } = useSymbol()
const router = useRouter()
const assetId = useRouteQuery('assetId')
const symbol = useRouteQuery('symbol')

const notification = useNotification()

const current = ref()
const balance = ref('-')
const approveBalance = ref('')
const amount = ref('')
const errorMsg = ref('')
const isLoadingBalance = ref(false)
const isDeposit = ref(false)
const isConfirm = ref(false)
const isSuccess = ref(false)
const successSeconds = ref(3)

const currentItem = computed(() => getAssetItem(current.value))
const isRegistered = computed(() => userInfo.value.registeredOnChain)
const depositLimit = computed(() => {
  const chainConfig = getChainConfigSync()
  const currentConfig = chainConfig.find(i => `${i.id}` === `${currentChainId.value}`)
  return +currentConfig.nativeCurrencyDecimals - +currentItem.value?.decimal
})

function goBack() {
  if (history.length) {
    router.back()
  }
  else {
    router.replace('/portfolio/spot')
  }
}

function toMaxAmount() {
  if (balance.value !== '-') {
    const limit = depositLimit.value
    const precision = +currentItem.value?.decimal
    if (current.value === 0) {
      amount.value = `${Math.max(0, +balance.value - 0.01)}`
      const b = `${+balance.value - 0.01}`
      amount.value = `${b.split('.')[0].slice(-1 * limit)}${b.includes('.') ? '.' : ''}${!b.includes('.') ? '' : b.split('.')?.[1]?.slice(0, precision)}`
    }
    else {
      amount.value = `${balance.value.split('.')[0].slice(-1 * limit)}${balance.value.includes('.') ? '.' : ''}${!balance.value.includes('.') ? '' : balance.value.split('.')?.[1]?.slice(0, precision)}`
    }
  }
}

function onSearchAsset(filter: string, option: any) {
  const assetName = assetList.value.find(i => `${i.assetId}` === `${option.assetId}`)?.assetName
  return assetName.toLowerCase().includes(filter.toLowerCase())
}

async function getBalance(currency: string) {
  const current = getAssetItem(currency)
  if (!current) {
    balance.value = '-'
    return
  }
  isLoadingBalance.value = true
  if (currency === '0') {
    return _getBalance(getWagmiConfig(), {
      address: address.value,
    }).then((res) => {
      balance.value = formatEther(res.value)
    }).finally(() => {
      isLoadingBalance.value = false
    })
  }
  else {
    return getBalanceFromChain(current.address as `0x${string}`).then((res: any) => {
      balance.value = formatUnits(res, +current.onChainDecimal)
    }).finally(() => {
      isLoadingBalance.value = false
    })
  }
}
async function getApproveBalance(currency: string) {
  if (currency === '0') {
    approveBalance.value = balance.value
  }
  else {
    const current = getAssetItem(currency)
    getAllowanceFromChain(current.address as `0x${string}`).then((res: any) => {
      approveBalance.value = formatUnits(res, +current.onChainDecimal)
    })
  }
}

async function handleChangeCurrency(currency: string) {
  amount.value = ''
  await getBalance(currency)
  getApproveBalance(currency)
}

async function getAdminSignature() {
  const res = await vesselApiServer.getAdminSignature(currentChainId.value)
  const sig = res.data.signature
  return {
    operator: res.data.operator,
    r: sig.slice(0, 66),
    s: `0x${sig.slice(66, 130)}`,
    v: hexToNumber(`0x${sig.slice(130, 132)}`),
  }
}

async function notifySuccess(asset: string) {
  notification.success({
    title: 'Deposit Successful',
    content: `Your deposit of ${asset} has been successfully processed.`,
    duration: 2000,
  })
  await getBalance(current.value)
  getApproveBalance(current.value)
}

async function notifyError(asset: string) {
  notification.success({
    title: 'Deposit Error',
    content: `Your deposit of ${asset} has been failed.`,
    duration: 2000,
  })
  await getBalance(current.value)
  getApproveBalance(current.value)
}

async function notifyWaiting(asset: string) {
  // notification.success({
  //   title: 'Deposit Committed',
  //   content: `Please wait for the blockchain confirmation for your deposit of ${asset}.`,
  //   duration: 2000,
  // })
  await getBalance(current.value)
  getApproveBalance(current.value)
  isDeposit.value = false
  isSuccess.value = true
  setInterval(() => {
    successSeconds.value -= 1
  }, 1000)
}

watch(successSeconds, (val) => {
  if (val === 0) {
    goBack()
  }
})

const errorMsgDeposit = computed(() => {
  if (+amount.value > +balance.value) {
    return 'Insufficient balance for deposit.'
  }
  return ''
})

async function deposit() {
  await checkChain()
  if (isDeposit.value || +amount.value > +balance.value || !+amount.value) {
    return
  }
  let reg: RegExp
  if (currentItem.value?.decimal) {
    reg = new RegExp(`^\\d{1,${depositLimit.value}}(\\.\\d{1,${currentItem.value?.decimal}})?$`)
  }
  else {
    reg = new RegExp(`^\\d{1,${depositLimit.value}}$`)
  }
  if (!reg.test(amount.value)) {
    errorMsg.value = `Deposit amount must be < ${10 ** +depositLimit.value} with < ${currentItem.value?.decimal} decimals.`
    return
  }
  try {
    const _amount = amount.value
    isDeposit.value = true
    const depositAmount = parseUnits(amount.value, +currentItem.value?.onChainDecimal)
    const innerPublicKey = vesselKey.value.publicKey
    // const innerPublicKey = vesselKey.value.publicKey.slice(2)

    if (+current.value !== 0) {
      // not enough

      if (+approveBalance.value < +_amount) {
        const hash = await approveAmount(currentItem.value?.address as `0x${string}`, +currentItem.value?.onChainDecimal, amount.value)
        await waitForTransactionReceipt(getWagmiConfig(), {
          hash,
          retryCount: 20,
        })
        await getApproveBalance(current.value)
      }
      if (!isRegistered.value) {
        const { v, r, s, operator } = await getAdminSignature()
        await writeVault('registerAndDepositERC20', [
          innerPublicKey,
          operator,
          v,
          r,
          s,
          currentItem.value?.assetId,
          depositAmount,
        ]).then(() => {
          notifyWaiting(`${_amount} ${currentItem.value?.assetName}`)
        })
      }
      else {
        await writeVault('depositERC20', [
          currentItem.value?.assetId,
          depositAmount,
          innerPublicKey,
        ]).then(() => {
          notifyWaiting(`${_amount} ${currentItem.value?.assetName}`)
        })
      }
    }
    else {
      // ETH
      if (!isRegistered.value) {
        const { v, r, s, operator } = await getAdminSignature()
        await writeVault('registerAndDepositNative', [
          innerPublicKey,
          operator,
          v,
          r,
          s,
        ], depositAmount).then(() => {
          notifyWaiting(`${_amount} ${currentItem.value?.assetName}`)
        })
      }
      else {
        await writeVault('depositNative', [
          innerPublicKey,
        ], depositAmount).then(() => {
          notifyWaiting(`${_amount} ${currentItem.value?.assetName}`)
        })
      }
    }
  }
  catch (e: any) {
    console.log(e)
    isDeposit.value = false
  }
}

function toFaucet() {
  router.push(`/faucet?token=${currentItem.value?.address}`)
}

onMounted(() => {
  checkChain()
  if (assetId.value) {
    current.value = assetId.value
  }
  else if (symbol.value) {
    const pairs = getSymbolItem(symbol.value as string)
    current.value = pairs.baseAssetId
  }
  else if (!current.value) {
    current.value = getAssetItem('USDT')?.assetId
  }
  handleChangeCurrency(current.value)
})

function onConfirm() {
  if (isDeposit.value || +amount.value > +balance.value || !+amount.value) {
    return
  }
  isConfirm.value = true
}

function renderLabel(option: SelectOption) {
  return (
    <div class="flex items-center justify-start">
      <VIcon currency={option.assetName as string} class="mr-0.08 h-0.24 w-0.24" />
      {option.assetName}
    </div>
  )
}
</script>

<template>
  <div class="absolute inset-0 bg-black1">
    <div class="mx-auto w-6.4">
      <div class="flex items-center text-caption1 text-grey1">
        <v-back v-if="!isSuccess" name="Back" class="text-caption1" @click="goBack" />
      </div>
      <div v-if="!isConfirm">
        <div class="mt-0.14 text-center text-headline4">
          Deposit
        </div>
        <v-alert v-if="!IS_MAINNET" type="info" hide-icon class="mb-0.24 mt-0.12">
          Remember to visit our
          <span class="cursor-pointer text-primary underline" @click="toFaucet">Faucet page</span>
          daily to claim your test tokens and supercharge your trial trades, risk-free.
        </v-alert>
        <div class="mb-0.12 text-0.16 text-grey1">
          Select Asset
        </div>
        <n-select
          v-model:value="current"
          :render-label="renderLabel"
          :options="assetList"
          filterable :filter="onSearchAsset"
          label-field="assetName" value-field="assetId"
          @update-value="handleChangeCurrency"
        />
        <div class="mb-0.12 mt-0.32 text-0.16 text-grey1">
          Amount
        </div>
        <v-input
          v-model="amount" align="left"
          :error-message="errorMsg || errorMsgDeposit"
          message-placement="bottom"
          :precision="+currentItem?.decimal" @input="errorMsg = ''"
        >
          <template #suffix>
            <v-button type="outline" size="small" @click="toMaxAmount">
              MAX
            </v-button>
          </template>
        </v-input>
        <div class="mt-0.12 flex justify-end gap-x-0.04 text-0.12 text-grey1 font-600">
          <div>Available</div>
          <div class="flex gap-x-0.04 text-white2">
            <svg-loading-in-button v-if="isLoadingBalance" class="animate-spin animate-duration-2000" />
            <span v-else>{{ formatInt(balance) }}</span> {{ currentItem?.assetName }}
          </div>
        </div>
        <v-button
          class="mt-0.32 w-full"
          :loading="isDeposit"
          :disabled="+amount > +balance || !amount" @click="onConfirm"
        >
          Confirm
        </v-button>
        <div class="mt-0.32 text-center text-0.12 text-grey1 font-600">
          Your deposit is queued for processing on blockchain and should be completed within approximately 5 minutes.
        </div>
      </div>
      <div v-else>
        <div v-if="!isSuccess" class="mb-0.24 mt-0.14">
          <div v-if="!isDeposit" class="flex justify-center text-headline4">
            Deposit Confirm
          </div>
          <div v-else class="flex justify-center text-headline4">
            <div v-if="+amount > +approveBalance">
              Enable
            </div>
            <div v-else>
              Deposit
            </div>
          </div>
        </div>
        <div v-else class="flex flex-col items-center justify-center">
          <svg-success class="mb-0.12 h-0.54 w-0.54" fill="#58bd7d" />
          <div class="flex items-center gap-x-0.08 text-body1b text-white1">
            <VIcon :currency="currentItem.assetName" class="h-0.24 w-0.24" />
            {{ amount }}
            {{ currentItem.assetName }}
          </div>
          <div class="mb-0.48 mt-0.04 text-caption2b text-grey1">
            Deposit Submitted
          </div>
        </div>
        <div class="mb-0.08 flex justify-between">
          <div class="text-body2 text-grey2">
            Network
          </div>
          <div class="flex items-center gap-x-0.04 text-white1">
            <svg-scroll />
            Scroll
          </div>
        </div>
        <div class="mb-0.08 flex justify-between">
          <div class="text-body2 text-grey2">
            Asset
          </div>
          <div class="flex items-center gap-x-0.04 text-white1">
            <VIcon :currency="currentItem.assetName" class="h-0.16 w-0.16" />
            {{ currentItem.assetName }}
          </div>
        </div>
        <div class="mb-0.08 flex justify-between">
          <div class="text-body2 text-grey2">
            Address
          </div>
          <div class="flex items-center gap-x-0.04 text-white1">
            {{ address }}
          </div>
        </div>
        <div class="mb-0.32 mt-0.24 bg-black3 pt-0.01" />
        <div v-if="!isSuccess" class="flex flex-col items-center justify-center">
          <div class="mb-0.04 text-caption2b text-grey1">
            Deposit Amount
          </div>
          <div class="flex items-center gap-x-0.08 text-body1b text-white1">
            <VIcon :currency="currentItem.assetName" class="h-0.24 w-0.24" />
            {{ amount }}
            {{ currentItem.assetName }}
          </div>
        </div>
        <div v-if="!isSuccess">
          <v-button
            v-if="isDeposit" class="mt-0.32 w-full"
            :loading="isDeposit"
            :disabled="+amount > +balance || !amount || isDeposit" @click="deposit"
          >
            Sign in Wallet
          </v-button>
          <div v-else class="mt-0.32 flex items-center gap-x-0.32">
            <v-button
              v-if="!isDeposit" class="flex-1" type="outline"
              @click="isConfirm = false"
            >
              Previous
            </v-button>
            <v-button
              class="flex-1" :loading="isDeposit"
              @click="deposit"
            >
              Sign in Wallet
            </v-button>
          </div>
        </div>
        <div v-else>
          <v-button
            class="mt-0.32 w-full"
            @click="goBack"
          >
            Back ({{ successSeconds }}s)
          </v-button>
        </div>
      </div>
    </div>
  </div>
</template>
