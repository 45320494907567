<script setup lang="ts">
import { useAsyncValidator } from '@vueuse/integrations/useAsyncValidator'
import BN from 'bignumber.js'
import { usePutOrder } from '../limit'

const props = defineProps<{
  // type: 'sell' | 'buy'
}>()

const emit = defineEmits(['submit'])
const { currentSymbol, feeList } = useSymbol()
const { assetBalance, symbolPrice } = useWs()
const { isSigned } = useKey()
const { isConnected, preference, updatePreference, userInfo } = useUser()
const notification = useNotification()
const { userAssetList } = useData()

const isFilledPrice = ref(false)
const showConfirmModal = ref(false)
const fieldEmpty = ref<Record<string, boolean>>({})
const isSubmit = ref(false)

const currentSymbolItem = computed(() => getSymbolItem(currentSymbol.value))
const currentPrecision = computed(() => getPrecisionFromSymbol(currentSymbol.value))
const baseAsset = computed(() => currentSymbolItem.value?.base)
const quoteAsset = computed(() => currentSymbolItem.value?.quote)
const currentPricePrecision = computed(() => currentPrecision.value.price)

const { run: placeOrder, isLoading: isLoadingSubmit } = useHttp(vesselApiServer.placeOrder)
const { form, type, isChangePrice } = usePutOrder()

const feeInfo = computed(() => {
  const { takerFee, makerFee } = getUserFee(currentSymbol.value, feeList.value, userInfo.value)
  return {
    takerFee,
    makerFee,
    estFee: type.value === 'buy' ? BN(+form.value.buy.amount || 0).multipliedBy(BN(takerFee)).toFixed() : BN(+form.value.sell.total || 0).multipliedBy(BN(takerFee)).toFixed(),
    unit: type.value === 'buy' ? baseAsset : quoteAsset,
  }
})
const { pass, isFinished, errorFields, execute: validateFields } = useAsyncValidator(form, computed(() => ({
  [type.value]: {
    type: 'object',
    fields: {
      price: [

      ],
      amount: [

        {
          trigger: ['blur'],
          validator(rule: any, value: any) {
            if (!value || value === '0') {
              return true
            }

            if (currentPrecision.value.base >= 0) {
              return true
            }
            if (value.includes('.')) {
              return false
            }
            const zeroNum = value.match(/^.*[123456789](0*)$/)
            if (zeroNum?.[1].length < -currentPrecision.value.base) {
              return false
            }
            return true
          },
          message: `Enter an integral multiple of ${formatNumber(10 ** -currentPrecision.value.base, 0)} or it will be rounded to ${formatNumber(10 ** -currentPrecision.value.base, 0)}`,
        },
        // {
        //   pattern: currentTickerItem.value.baseAssetPrecision > 0 ? new RegExp(`^\\d+(\\.\\d{1,${currentTickerItem.value.baseAssetPrecision}})?$`) : /^\d+$/,
        //   message: `Number decimals must be under ${currentTickerItem.value.baseAssetPrecision}`,
        // },
      ],
    },
  },
})), {
  manual: false,
})

const confirmationModalProps = computed(() => [
  { label: 'Side', value: `${type.value.slice(0, 1).toUpperCase()}${type.value.slice(1)}` },
  { label: 'Type', value: 'Limit' },
  { label: 'Price', value: `${form.value[type.value].price} ${quoteAsset.value}` },
  { label: 'Amount', value: `${form.value[type.value].amount} ${baseAsset.value}` },
])

const tipOptions = [
  'GTC',
  'IOC',
  'FOK',
]

const availableBalance = computed(() => {
  const availableAsset = type.value === 'buy' ? quoteAsset.value : baseAsset.value
  return +(userAssetList.value?.find(i => i.assetName === availableAsset)?.available || '0')
})
const maxAmount = computed(() => type.value === 'buy' ? (form.value[type.value].price ? availableBalance.value / +form.value[type.value].price : 0) : availableBalance.value)

function formatPrecision(value: number | string, precision: number) {
  if (precision >= 0) {
    return (+value).toFixed(precision)
  }
  const a = 10 ** (-precision)
  return `${Math.ceil(+value / a) * a}`
}

function onBlurAmount() {
  fieldEmpty.value = {}
  if (form.value[type.value].amount !== '' && +currentPrecision.value.base < 0) {
    form.value[type.value].amount = formatPrecision(form.value[type.value].amount, +currentPrecision.value.base)
  }
}

function onChangeAmount() {
  fieldEmpty.value = {}
  const { price, amount } = form.value[type.value]
  if (price && amount) {
    form.value[type.value].total = `${+BN(price).multipliedBy(BN(amount)).decimalPlaces(+currentPrecision.value.quote).toFixed()}`
    form.value[type.value].percent = BN(amount).dividedBy(BN(maxAmount.value)).multipliedBy(BN(100)).toNumber()
  }
}
watch(isChangePrice, onChangeAmount)
function onChangePrice() {
  fieldEmpty.value = {}
  const { price, amount } = form.value[type.value]
  if (amount) {
    form.value[type.value].total = `${+BN(price).multipliedBy(BN(amount)).decimalPlaces(+currentPrecision.value.quote).toFixed()}`
    form.value[type.value].percent = BN(amount).dividedBy(BN(maxAmount.value)).multipliedBy(BN(100)).toNumber()
  }
}
function onChangeTotal() {
  fieldEmpty.value = {}
  const { price, amount, total } = form.value[type.value]
  if (price && total) {
    const realAmount = formatPrecision(BN(total).dividedBy(BN(price)).toNumber(), +currentPrecision.value.base)
    form.value[type.value].amount = realAmount
    form.value[type.value].percent = BN(realAmount).dividedBy(BN(maxAmount.value)).multipliedBy(BN(100)).toNumber()
  }
}
function onChangeAmountPercent(percent: number) {
  fieldEmpty.value = {}
  const { price } = form.value[type.value]
  if (price) {
    const p = currentPrecision.value.base
    if (p > 0) {
      form.value[type.value].amount = `${BN(maxAmount.value).multipliedBy(percent).dividedBy(BN(100)).decimalPlaces(p, BN.ROUND_FLOOR).toFixed()}`
    }
    else {
      form.value[type.value].amount = formatPrecision(BN(maxAmount.value).multipliedBy(percent).dividedBy(BN(100)).toNumber(), p)
    }
    form.value[type.value].total = `${+BN(price).multipliedBy(form.value[type.value].amount).toFixed(currentPricePrecision.value)}`
  }
}

function getErrorMessage(type: string, field: string) {
  return errorFields.value[`${type}.${field}`]?.[0]?.message || (fieldEmpty.value[`${type}.${field}`] && `${field.slice(0, 1).toUpperCase()}${field.slice(1)} is required`) || ''
}

const disableSubmit = computed(() => {
  if (type.value === 'buy' && +form.value[type.value].total > availableBalance.value) {
    return true
  }
  if (type.value === 'sell' && +form.value[type.value].amount > availableBalance.value) {
    return true
  }
  return false
})

function validateEmptyFields() {
  const valueKeys = ['amount', 'price', 'total']
  for (const key of valueKeys) {
    if (!+form.value[type.value][key as keyof typeof form.value.sell]) {
      fieldEmpty.value[`${type.value}.${key}`] = true
    }
  }
}

async function preSubmitOrder() {
  await validateFields()
  validateEmptyFields()
  if (disableSubmit.value || Object.keys(errorFields.value).length > 0 || Object.keys(fieldEmpty.value).length > 0) {

  }
  // if (preference.value.orderConfirmation) {
  //   showConfirmModal.value = true
  // }
  else {
    submitOrder()
  }
}

async function submitOrder() {
  if (isSubmit.value) {
    return
  }
  showConfirmModal.value = false
  isSubmit.value = true

  const ts = `${new Date().getTime()}`

  const order: Omit<PlaceOrderRequest, 'signature'> = {
    symbolName: currentSymbolItem.value.symbolName,
    timestamp: ts,
    nonce: ts,
    side: type.value.toUpperCase() as 'SELL' | 'BUY',
    type: 'LIMIT',
    timeInForce: form.value[type.value].tif,
    size: form.value[type.value].amount,
    price: form.value[type.value].price,
    respType: 'FULL',
  }
  const { signature, timestamp } = await signOrder(order, 4)

  placeOrder({
    ...order,
    signature,
  }, {
    headers: {
      'VESSEL-TIMESTAMP': timestamp,
    },
  }).then((res) => {
    if (res.data.error) {
      return
    }
    emit('submit')
    form.value.buy.amount = ''
    form.value.sell.amount = ''
    form.value.buy.total = ''
    form.value.sell.total = ''
    form.value.buy.percent = 0
    form.value.sell.percent = 0
    onChangeAmount()
    if (preference.value.tradeNotification) {
      notification.success({
        title: 'Limit Order Confirmation',
        duration: 2000,
        content: () => h('div', {}, {
          default: () => [
            h('div', {}, { default: () => `Your ${type.value.toLocaleUpperCase()} limit order for ${res.data.origQty} ${baseAsset.value} with ${formatNumber(+res.data.origQty * +res.data.price, currentPrecision.value.quote)} has been placed successfully.` }),
          ],
        }),
      })
    }
  }).finally(() => {
    isSubmit.value = false
  })
}

function handleChangePreference() {
  preference.value.orderConfirmation = !preference.value.orderConfirmation
  updatePreference()
}

function handleConfirmModal() {
  showConfirmModal.value = false
}

const hideOrderConfirmation = computed(() => !preference.value.orderConfirmation)

watch([currentSymbolItem, symbolPrice], (newValue, prevValue) => {
  const newSymbol = newValue[0]?.symbolName
  const prevSymbol = prevValue[0]?.symbolName
  if (newSymbol !== prevSymbol && symbolPrice.value[newSymbol]) {
    form.value.buy.price = (+symbolPrice.value[newSymbol]?.closePrice).toFixed(currentPricePrecision.value)
    form.value.sell.price = (+symbolPrice.value[newSymbol]?.closePrice).toFixed(currentPricePrecision.value)
    form.value.buy.amount = ''
    form.value.sell.amount = ''
    form.value.buy.total = ''
    form.value.sell.total = ''
    form.value.buy.percent = 0
    form.value.sell.percent = 0
    isFilledPrice.value = true
  }
  if (form.value[type.value].price) {
    return
  }
  if (!isFilledPrice.value && symbolPrice.value[newSymbol]) {
    form.value.buy.price = (+symbolPrice.value[newSymbol]?.closePrice).toFixed(currentPricePrecision.value)
    form.value.sell.price = (+symbolPrice.value[newSymbol]?.closePrice).toFixed(currentPricePrecision.value)
    form.value.buy.amount = ''
    form.value.sell.amount = ''
    form.value.buy.total = ''
    form.value.sell.total = ''
    form.value.buy.percent = 0
    form.value.sell.percent = 0
    isFilledPrice.value = true
  }
}, { immediate: true })
</script>

<template>
  <div class="flex flex-col gap-y-0.12">
    <v-input
      v-model="form[type].price" prefix="Price"
      :suffix="quoteAsset" :error-message="getErrorMessage(type, 'price')"
      :precision="currentPricePrecision" @input="onChangePrice"
    />
    <v-input
      v-model="form[type].amount" prefix="Amount"
      :suffix="baseAsset" :error-message="getErrorMessage(type, 'amount')"
      :precision="currentPrecision.base" @input="onChangeAmount" @blur="onBlurAmount"
    />
    <v-progress v-model="form[type].percent" @change="onChangeAmountPercent" />
    <v-input
      v-model="form[type].total" prefix="Total" :suffix="quoteAsset"
      :error-message="getErrorMessage(type, 'total')"
      :precision="currentPrecision.quote" @input="onChangeTotal"
    />
    <div class="flex flex-col gap-y-0.1 text-0.12">
      <div class="flex">
        <div class="mr-0.04 text-grey1">
          Available
        </div>
        <div>{{ availableBalance }} {{ type === 'buy' ? quoteAsset : baseAsset }}</div>
      </div>
      <div class="flex items-center">
        <div class="text-grey1">
          Est.Fee
        </div>
        <v-question>
          <div class="text-caption2">
            <span class="text-white2">Maker: {{ getRatio(feeInfo.makerFee, -1) }}, Taker: {{ getRatio(feeInfo.takerFee, -1) }}</span>
            <span class="ml-0.04 cursor-pointer text-primary underline" @click="openUrl('')">Learn</span>
          </div>
        </v-question>
        <div class="ml-0.04">
          {{ formatNumber(feeInfo?.estFee, 8) }} {{ feeInfo.unit }}
        </div>
      </div>
      <div class="w-full bg-black2 pt-0.01" />
      <div class="flex items-center justify-end">
        <div class="mr-0.04 text-grey1">
          TIF
        </div>
        <v-select v-model="form[type].tif" :options="tipOptions" small plain class="h-0.28 w-0.7" />
      </div>
    </div>

    <common-connect-wallet-button v-if="!isConnected" />
    <common-sign-for-trading-button v-else-if="!isSigned" />
    <v-button
      v-else type="primary" :bg="type === 'sell' ? 'red' : 'green'"
      :disabled="disableSubmit" :loading="isLoadingSubmit || isSubmit"
      @click="preSubmitOrder"
    >
      <span class="mr-0.04 capitalize">{{ type }}</span> {{ baseAsset }}
    </v-button>
    <v-modal v-model:show="showConfirmModal" title="Order Confirmation" :z-index="201" title-class="text-body1b">
      <div class="w-4.48 text-grey1 font-400">
        <div class="mt-0.22 flex items-center text-0.16">
          <v-icon :currency="baseAsset" class="mr-0.04 h-0.2 w-0.2" />
          <span class="text-white2">{{ baseAsset }}</span><span>/{{ quoteAsset }}</span>
        </div>
        <div class="grid grid-cols-2 mt-0.32 gap-y-0.14 text-0.12">
          <div v-for="item in confirmationModalProps" :key="item.label">
            <div class="mb-0.04 text-caption2">
              {{ item.label }}
            </div>
            <div class="text-caption1b" :class="item.label === 'Side' ? type === 'sell' ? 'text-red' : 'text-green' : 'text-white2'">
              {{ item.value }}
            </div>
          </div>
        </div>
        <n-checkbox
          :checked="hideOrderConfirmation" class="mt-0.32 text-0.12 font-600"
          @update:checked="handleChangePreference"
        >
          <div class="text-caption2b">
            Don't show again
          </div>
        </n-checkbox>
      </div>
      <template #footer>
        <div class="mt-0.32 flex gap-x-0.16">
          <v-button type="outline" class="flex-1" @click="showConfirmModal = false">
            Cancel
          </v-button>
          <v-button class="flex-1" @click="submitOrder">
            Confirm
          </v-button>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<style scoped>

</style>
