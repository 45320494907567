<script setup lang="tsx">
import { useRouteQuery } from '@vueuse/router'
import type { SelectOption } from 'naive-ui'
import VIcon from '@/components/v/Icon.vue'
import type { AssetItem } from '~/service/modules/user'
import type { BalanceItem } from '~/store/ws'

const { modal, closeModal } = useModal()

const { assetBalance: _assetBalance } = useWs()
const { assetList } = useUser()
const notification = useNotification()
const { currencyList } = useSymbol()
const message = useMessage()

const current = ref()
const balance = ref('-')
const amount = ref('')
const isChecking = ref(false)
const assetId = useRouteQuery('assetId')
const symbol = useRouteQuery('symbol')

const assetBalance = computed<AssetItem[] | BalanceItem[]>(() => _assetBalance.value.length ? _assetBalance.value : assetList.value)
const currentBalance = computed(() => assetBalance.value.find(i => i.assetName === current.value))
const currentItem = computed(() => currencyList.value.find(i => i.assetName === current.value))

const { run: callWithdraw, isLoading } = useHttp(vesselApi.user.withdraw)

function toMaxAmount() {
  amount.value = `${currentBalance.value.available}`
}

function onChangeCurrency() {
  amount.value = ''
}

const errorMsg = computed(() => {
  if (!isChecking.value) {
    return ''
  }
  const item = currencyList.value.find(i => i.assetName === currentBalance.value.assetName)
  const reg = new RegExp(`^\\d+(\\.\\d{1,${item.onChainDecimal}})?$`)
  if (!reg.test(amount.value)) {
    return `Withdraw number decimals must be under ${item.onChainDecimal}`
  }
  if (+amount.value < +item.minWithdrawSize) {
    return (`Minimum withdrawal for ${item.assetName} is ${item.minWithdrawSize}`)
  }
  if (+amount.value > +item.maxWithdrawSize) {
    return `Maximum withdrawal for ${item.assetName} is ${item.maxWithdrawSize}`
  }
  if (+amount.value > +currentBalance.value?.available) {
    return 'Insufficient balance for withdrawal.'
  }
})
const disabled = computed(() => !+amount.value)

async function withdraw() {
  isChecking.value = true

  if (disabled.value || errorMsg.value) {
    return
  }

  callWithdraw({
    assetName: currentBalance.value.assetName,
    amount: +amount.value,
    clientOrderId: `${new Date().getTime()}`,
  }).then((res) => {
    if (res.data.error) {
      return
    }
    notification.success({
      title: 'Withdraw',
      content: `Withdraw request successfully. This record can be seen in history. You can claim it when status becomes success`,
      duration: 2000,
    })
    amount.value = ''
    closeModal('withdraw')
  })
}

function renderLabel(option: SelectOption) {
  return (
    <div class="flex items-center justify-start">
      <VIcon currency={option.assetName as string} class="mr-0.08 h-0.24 w-0.24" />
      {option.assetName}
    </div>
  )
}

whenever(() => modal.value.withdraw, () => {
  isChecking.value = false
  amount.value = ''
  if (assetId.value) {
    const assetName = currencyList.value.find(i => i.assetId === assetId.value)?.assetName
    current.value = assetName
  }
  else if (symbol.value) {
    const pairs = getSymbolItem(symbol.value as string)
    current.value = pairs.base
  }
  else if (!current.value) {
    current.value = assetBalance.value[0]?.assetName
  }
})
</script>

<template>
  <v-modal
    v-model:show="modal.withdraw"
    modal-class="w-4.48"
    title="Withdraw"
  >
    <div class="mb-0.12 text-0.16 text-grey1">
      Select Asset
    </div>
    <n-select
      v-model:value="current"
      :render-label="renderLabel"
      :options="assetBalance"
      filterable
      label-field="assetName" value-field="assetName"
      @update:value="onChangeCurrency"
    />
    <div class="mb-0.12 mt-0.32 text-0.16 text-grey1">
      Amount
    </div>
    <v-input v-model="amount" align="left" :error-message="errorMsg" :precision="+currentItem.depositPrecision" @input="errorMsg = ''">
      <template #suffix>
        <v-button type="outline" size="small" @click="toMaxAmount">
          MAX
        </v-button>
      </template>
    </v-input>
    <div class="mt-0.12 flex justify-end gap-x-0.04 text-0.12 text-grey1 font-600">
      <div>Available</div>
      <div class="flex gap-x-0.04 text-white2">
        {{ currentBalance?.available }}
      </div>
    </div>
    <div class="mt-0.32 rd-0.12 bg-#18191D px-0.12 py-0.2 font-dm">
      <div class="text-0.16 text-white2 font-poppins">
        Quick Guide to Withdrawals:
      </div>
      <div class="mt-0.1 flex items-center gap-x-0.16 rd-full bg-black2 p-0.08 text-0.14 font-700">
        <div class="h-0.32 w-0.32 flex flex-center flex-none rd-full bg-black3 bg-black3 text-white2">
          1
        </div>
        <div class="">
          <div class="text-white2">
            Initiate Withdrawal:
          </div>
          <div class="text-grey1 leading-0.16">
            Anticipate blockchain processing to finalize in about 2 hours.
          </div>
        </div>
      </div>
      <div class="mt-0.1 flex items-center gap-x-0.16 rd-full bg-black2 p-0.08 text-0.14 font-700">
        <div class="h-0.32 w-0.32 flex flex-center flex-none rd-full bg-black3 bg-black3 text-white2">
          2
        </div>
        <div class="">
          <div class="text-white2">
            Claim Your Assets:
          </div>
          <div class="text-grey1 leading-0.16">
            Once the withdrawal is confirmed, proceed to claim your assets to your wallet.
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <v-button
        class="mt-0.32 w-full"
        :loading="isLoading"
        :disabled="disabled" @click="withdraw"
      >
        Confirm Withdraw
      </v-button>
    </template>
  </v-modal>
</template>

<style scoped>
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
