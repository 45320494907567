import type { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import axios from 'axios'

// Constants
const HTTP_STATUS = {
  INTERNAL_ERROR: 500,
  UNAUTHORIZED: 401,
} as const

const ERROR_MESSAGES = {
  INTERNAL_ERROR: 'Internal server error',
} as const

const publicInstance = axios.create({
  baseURL: '/api/v1/public',
})

const encryptedInstance = axios.create({
  baseURL: '/api/v1/me',
})

// Types
interface ApiResponse<T = any> {
  data: T
  message?: string
}

// Common error handler
function handleApiError(error: AxiosError, instance: AxiosInstance) {
  const status = error.response?.status
  const errorData = error.response?.data as ApiResponse

  if (status === HTTP_STATUS.INTERNAL_ERROR) {
    globalMessage.error(ERROR_MESSAGES.INTERNAL_ERROR)
    return Promise.reject(error)
  }

  if (instance === encryptedInstance && status === HTTP_STATUS.UNAUTHORIZED) {
    disconnectWallet()
    location.reload()
  }

  if (errorData?.message) {
    globalMessage.error(errorData.message)
    return Promise.reject(errorData)
  }

  return Promise.reject(error)
}

// Common response transformer
function transformResponse<T>(response: AxiosResponse<T>) {
  return response
}

// Request interceptor for secret instance
encryptedInstance.interceptors.request.use((config) => {
  const headers = getApiHeader(config)
  if (config.headers && headers) {
    Object.entries(headers).forEach(([key, value]) => {
      config.headers.set(key, value)
    })
  }
  return config
})

// Response interceptors
publicInstance.interceptors.response.use(
  transformResponse,
  error => handleApiError(error, publicInstance),
)

encryptedInstance.interceptors.response.use(
  transformResponse,
  error => handleApiError(error, encryptedInstance),
)

export const secretHttp = encryptedInstance
export const publicHttp = publicInstance
