import stats from './modules/stats'
import user from './modules/user'
import order from './modules/order'
import publicApi from './modules/public'
import amm from './modules/amm'

export const vesselApi = {
  stats,
  user,
  public: publicApi,
  order,
  amm,
}

export { default as vesselApiServer } from './api'
