import { useRouteQuery } from '@vueuse/router'

// import type { CurrencyItem, FastWithdrawAssetItem, FastWithdrawBalanceItem, FeeItem, SymbolItem, TickerSymbol } from '~/service/modules/public'

export const useSymbol = createGlobalState(() => {
  const currentSymbol = useStorage<string>('currentSymbol', null)
  const symbolQuery = useRouteQuery('symbol')

  const symbolList = ref<SymbolItem[]>([])
  const assetList = ref<AssetItem[]>([])
  const feeList = ref<FeeItem[]>([])
  // currencyList tickerInfo  feeInfo fasetWithdrawAssetList fastWithdrawBalanceList currentTickerItem

  async function init() {
    vesselApiServer.getFeeList().then((res) => {
      feeList.value = res.data
    })
    await vesselApiServer.getAllAssetList().then((res) => {
      assetList.value = res.data
    })
    vesselApiServer.getAllSymbolList().then((res) => {
      symbolList.value = res.data
    })
  }

  // vesselApi.public.getTickerInfo().then((res) => {
  //   tickerInfo.value = res
  // })
  // vesselApi.public.getFeeInfo().then((res) => {
  //   feeInfo.value = res.data.vipFeeRateDetails
  // })
  // vesselApi.public.getFastWithdrawAsset().then((res) => {
  //   fastWithdrawAssetList.value = res.map(i => ({ ...i, disabled: !i.enabled }))
  // })

  // getFastWithdrawBalance()
  // function getFastWithdrawBalance() {
  //   vesselApi.public.getFastWithdrawBalance().then((res) => {
  //     fastWithdrawBalanceList.value = res
  //   })
  // }
  init()

  function changeCurrentSymbol(symbol: string) {
    currentSymbol.value = symbol
    symbolQuery.value = symbol
  }

  return {
    symbolList,
    assetList,
    currentSymbol,
    feeList,
    changeCurrentSymbol,
    // currentTickerItem,
    // tickerInfo,
    // feeInfo,
    // fastWithdrawAssetList,
    // fastWithdrawBalanceList,
    // getFastWithdrawBalance,
  }
})
