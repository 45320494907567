<script setup lang="ts">
import { waitForTransactionReceipt, watchAsset } from '@wagmi/core'
import { useAccount, useWriteContract } from '@wagmi/vue'
import { parseUnits } from 'viem'
import faucetImg from '~/assets/image/faucet.png'
import { getWagmiConfig } from '~/lib/wagmi'

const { allMintList, mintedList } = useFaucet()
const { isSigned } = useKey()
const { isConnected, address } = useUser()
const { openModal } = useModal()
const notification = useNotification()
const route = useRoute()
const { isMobile } = useResponsive()
const { connector, chain } = useAccount()
const { checkChain, currentChainId } = useConnector()

const { writeContractAsync } = useWriteContract()

const current = ref('')
const isMint = ref(false)

const currentItem = computed(() => allMintList.value?.find(i => i.address === current.value))
async function addToken() {
  try {
    await watchAsset(getWagmiConfig(), {
      connector: connector.value,
      type: 'ERC20',
      options: {
        address: currentItem.value?.address,
        symbol: currentItem.value?.assetName,
        decimals: +currentItem.value?.onChainDecimal,
      },
    })
  }
  catch (e) {
    const err = e
  }
}

async function mintInChain() {
  const { address: tokenAddress, mintAmount, assetName, onChainDecimal: decimal } = currentItem.value

  faucetMint(tokenAddress as `0x${string}`, parseUnits(`${mintAmount}`, +decimal)).then((hash) => {
    isMint.value = false
    notification.success({
      title: 'Mint In Progress',
      duration: 2000,
      content: `Your ${formatNumber(mintAmount)} ${assetName} test tokens have been dispatched and should arrive shortly.`,
    })
    waitForTransactionReceipt(getWagmiConfig(), {
      hash,
      retryCount: 20,
    }).then((receipt) => {
      const mintCurrency = mintedList.value?.find(i => i.tokenAddress === tokenAddress)
      if (mintCurrency) {
        mintedList.value = mintedList.value.map(i => i.tokenAddress === tokenAddress
          ? {
              timestamp: `${new Date().getTime()}`,
              tokenAddress,
            }
          : i)
      }
      else {
        if (mintedList.value) {
          mintedList.value.push({
            tokenAddress,
            timestamp: `${new Date().getTime()}`,
          })
        }
      }
      vesselApiServer.addMint({
        chainId: `${currentChainId.value}`,
        assetAddress: current.value,
        transactionHash: hash,
      }).then(() => {
        notification.success({
          title: 'Mint Successful',
          duration: 2000,
          content: `Your ${formatNumber(mintAmount)} ${assetName} test tokens are now ready for deposit. Get ready to dive into trading!`,
        })
      })
    }).catch(() => {
      console.log('error wait for receipt')
    })
  }).catch((e) => {
    console.log(e)

    notification.error({
      title: 'Mint Not Accepted',
      duration: 2000,
      content: 'Make sure the transaction is authorized in your wallet, then please attempt to claim again.',
    })
    isMint.value = false
  })
}

async function mint() {
  await checkChain()
  if (isMint.value) {
    notification.error({
      title: 'Mint Paused',
      duration: 2000,
      content: `Your ${formatNumber(currentItem.value.mintAmount)} ${currentItem.value.assetName} test tokens are in transit. Please hold on while the transaction completes.`,
    })
    return
  }
  isMint.value = true
  if (current.value) {
    console.log(current.value, mintedList.value)
    const mintCurrency = mintedList.value?.find(i => i.tokenAddress.toLowerCase() === current.value.toLowerCase())
    if (mintCurrency) {
      const mintTs = +mintCurrency.timestamp
      const ts = new Date().getTime()
      console.log(ts, mintTs)

      // interval in second
      if ((ts - mintTs) < +currentItem.value.mintInterval * 1000) {
        notification.error({
          title: 'Mint Unavailable',
          duration: 2000,
          content: 'You\'ve snagged your test tokens in the past 24 hours. Circle back tomorrow to claim your next batch!',
        })
        isMint.value = false
        return
      }
    }
    mintInChain()
  }
}

function initCurrent() {
  if (allMintList.value?.length && route.query.token && route.query.token !== '0x') {
    current.value = (route.query.token as string).toLowerCase()
  }
  else {
    current.value = allMintList.value?.[0]?.address
  }
}

watch(allMintList, initCurrent)

onMounted(initCurrent)
</script>

<template>
  <div class="flex flex-1 flex-col items-center justify-center bg-black1 px-0.16 py-0.4">
    <div class="text-0.48 font-700 font-dm">
      Vessel Faucet
    </div>
    <div class="w-full text-left text-0.16 text-grey1 sm:w-5.44 sm:text-center">
      Start your journey on the Vessel Testnet with just a few clicks! Claim your test tokens now and begin exploring the trades.
    </div>
    <common-connect-wallet-button v-if="!isConnected" class="mt-0.4 w-1.68" />
    <common-sign-for-trading-button v-else-if="!isSigned" class="mt-0.4 w-1.68" />
    <template v-else>
      <!-- Step 1 -->
      <div class="mt-0.4 w-full rd-0.24 bg-black2 px-0.12 py-0.2 sm:w-8.64 sm:px-0.8 sm:py-0.32">
        <div class="text-0.12 text-grey1 font-700 leading-0.12 uppercase">
          STEP 1
        </div>
        <div class="my-0.16 text-0.24 text-white2">
          Get SepoliaETH as Gas
        </div>
        <div class="text-0.16 text-grey1">
          Start by visiting
          <a href="https://faucet.quicknode.com/drip" target="_blank">faucet.quicknode.com</a>
          to acquire gas to power your transactions on the Scroll Sepolia Test network.
        </div>
        <div class="mt-0.24 gap-0.14 sm:flex">
          <v-button class="w-full sm:w-3" @click="openUrl('https://faucet.quicknode.com/scroll/sepolia')">
            Get Scroll Sepolia ETH on QuickNode
          </v-button>
          <!-- <v-button class="mt-0.12 w-full sm:mt-0 sm:w-2.4" @click="openUrl('https://www.infura.io/faucet/sepolia')">
            Get SepoliaETH on Infura
          </v-button> -->
        </div>
      </div>
      <!-- Step 2 -->
      <div class="relative mt-0.4 w-full rd-0.24 bg-black2 px-0.16 py-0.2 sm:w-8.64 sm:px-0.8 sm:py-0.32">
        <img v-if="!isMobile" :src="faucetImg" class="absolute right-0.3 top-0.11 h-3.37 w-3.37">
        <div class="text-0.12 text-grey1 font-700 leading-0.12 uppercase">
          STEP 2
        </div>
        <div class="my-0.16 text-0.24 text-white2">
          Get Test Tokens
        </div>
        <n-select
          v-model:value="current" :options="allMintList" label-field="assetName"
          value-field="address" class="w-full sm:w-4.04" filterable
        />
        <div class="mb-0.12 mt-0.16 text-0.16 text-grey1">
          Amount/Day
        </div>
        <v-input
          disabled class="w-full sm:w-4.04" size="small"
          :model-value="formatNumber(currentItem?.mintAmount, 2)" align="left"
        />
        <div class="mt-0.24 w-full gap-0.14 sm:w-4.04 sm:flex">
          <v-button class="w-full flex-1 sm:w-auto" :loading="isMint" @click="mint">
            Mint {{ currentItem?.assetName }}
          </v-button>
          <v-button class="mt-0.16 w-full flex-1 flex-shrink-0 sm:mt-0 sm:w-auto" bg="black-3" color="primary" @click="addToken">
            Add {{ currentItem?.assetName }} to Wallet
          </v-button>
        </div>
        <div class="mt-0.32 flex items-center gap-0.04">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M7.38748 2.08514C7.61908 1.54844 8.38009 1.54844 8.61169 2.08514L10.065 5.45307C10.1631 5.68032 10.379 5.83454 10.6258 5.85361L14.3529 6.14161C14.9509 6.18781 15.1885 6.93852 14.726 7.32038L11.923 9.63456C11.7253 9.79779 11.6388 10.0599 11.7004 10.3088L12.563 13.7944C12.7048 14.3676 12.0865 14.8286 11.5776 14.529L8.3378 12.6217C8.12906 12.4988 7.8701 12.4988 7.66137 12.6217L4.42154 14.529C3.91268 14.8286 3.29432 14.3676 3.43618 13.7944L4.29881 10.3088C4.3604 10.0599 4.27382 9.79779 4.07612 9.63456L1.27319 7.32038C0.810685 6.93852 1.04829 6.18781 1.64628 6.14161L5.37338 5.85361C5.62016 5.83454 5.83606 5.68032 5.93413 5.45307L7.38748 2.08514Z" fill="#37AAEC" />
          </svg>
          <div class="test-white2 text-0.16">
            Daily Token Request Limitation
          </div>
        </div>
        <div class="mt-0.08 text-0.12 text-grey1 font-500">
          To ensure fair distribution and give everyone a chance, one user is eligible for request each type of test token once per day. If you need more test tokens or have any questions,
          please contact <a href="mailto:gm@vessel.finance">gm@vessel.finance</a>.
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>
a {
  color: var(--vessel-color-primary);
}
</style>
