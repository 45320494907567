<script setup lang="tsx">
import { useRouteQuery } from '@vueuse/router'
import type { SelectOption } from 'naive-ui'
import { formatUnits, parseUnits } from 'viem'
import { waitForTransactionReceipt } from '@wagmi/core'
import VIcon from '@/components/v/Icon.vue'
import { getWagmiConfig } from '~/lib/wagmi'

const { modal, closeModal } = useModal()

const { currencyList } = useSymbol()
const { address } = useUser()
const { checkChain } = useConnector()
const router = useRouter()

const notification = useNotification()

const current = ref()
const claimableBalance = ref('0')
const isLoadingClaimable = ref(false)
const isClaim = ref(false)
const assetId = useRouteQuery('assetId')
const symbol = useRouteQuery('symbol')
const isConfirm = ref(false)
const isSuccess = ref(false)
const successSeconds = ref(3)

function goBack() {
  if (history.length) {
    router.back()
  }
  else {
    router.replace('/portfolio/spot')
  }
}

const currentItem = computed(() => currencyList.value.find(i => i.assetId === current.value))

const disabled = computed(() => !+claimableBalance.value || isLoadingClaimable.value)

async function getClaimableBalance() {
  try {
    isLoadingClaimable.value = true
    const data = await getClaimableFromChain(+currentItem.value.assetId)
    claimableBalance.value = formatUnits(data, +currentItem.value.onChainDecimal)
    isLoadingClaimable.value = false
  }
  catch (e: any) {
    console.log(e)
  }
}

function onChangeCurrency() {
  claimableBalance.value = ''
  getClaimableBalance()
}

async function claim() {
  if (isClaim.value) {
    return
  }
  if (!disabled.value) {
    try {
      const claimValue = parseUnits(claimableBalance.value, +currentItem.value.onChainDecimal)
      isClaim.value = true
      await checkChain()
      await writeVault('withdraw', [currentItem.value.assetId, claimValue]).then((hash) => {
        notification.success({
          title: 'Claim Completed',
          content: `Your claim of ${claimableBalance.value} ${currentItem.value.assetName} has been successfully processed.`,
          duration: 2000,
        })
        waitForTransactionReceipt(getWagmiConfig(), {
          hash,
          retryCount: 20,
        }).then(() => {
          // notification.success({
          //   title: 'Claim Submitted',
          //   content: `Please wait for the blockchain confirmation for your claim of ${claimableBalance.value} ${currentItem.value.assetName}.`,
          //   duration: 2000,
          // })
          isClaim.value = false
          isSuccess.value = true
          setInterval(() => {
            successSeconds.value -= 1
          }, 1000)
        })
      })
    }
    catch (e) {
      isClaim.value = false
      console.log(e)
    }
  }
}

watch(successSeconds, (val) => {
  if (val === 0) {
    // goBack()
  }
})

function renderLabel(option: SelectOption) {
  return (
    <div class="flex items-center justify-start">
      <VIcon currency={option.assetName as string} class="mr-0.08 h-0.24 w-0.24" />
      {option.assetName}
    </div>
  )
}

onMounted(async () => {
  checkChain()
  if (assetId.value) {
    current.value = assetId.value
  }
  else if (symbol.value) {
    const pairs = getSymbolItem(symbol.value as string)
    current.value = pairs.baseAssetId
  }
  else if (!current.value) {
    current.value = currencyList.value?.find(i => i.assetName === 'USDT').assetId
  }
  onChangeCurrency()
})
</script>

<template>
  <div class="absolute inset-0 bg-black1">
    <div class="mx-auto w-6.4">
      <div class="flex items-center text-caption1 text-grey1">
        <v-back v-if="!isSuccess" name="Back" class="text-caption1" @click="goBack" />
      </div>
      <div v-if="!isSuccess" class="mb-0.24 mt-0.13 text-center text-headline4">
        Claim
      </div>
      <div v-else class="flex flex-col items-center justify-center">
        <svg-success class="mb-0.12 mt-0.2 h-0.54 w-0.54" fill="#58bd7d" />
        <div class="flex items-center gap-x-0.08 text-body1b text-white1">
          <VIcon :currency="currentItem.assetName" class="h-0.24 w-0.24" />
          {{ claimableBalance }}
          {{ currentItem.assetName }}
        </div>
        <div class="mb-0.48 mt-0.04 text-caption2b text-grey1">
          Claim Submitted
        </div>
      </div>
      <div v-if="!isConfirm">
        <div class="mb-0.12 text-0.16 text-grey1">
          Select Asset
        </div>
        <n-select
          v-model:value="current"
          :options="currencyList"
          :render-label="renderLabel"
          filterable
          label-field="assetName" value-field="assetId"
          @update:value="onChangeCurrency"
        />
        <div class="mb-0.12 mt-0.32 text-0.16 text-grey1">
          Amount
        </div>
        <div class="h-0.48 w-full flex items-center rd-0.12 bg-black3 px-0.16 text-grey1">
          <svg-loading-in-button v-if="isLoadingClaimable" class="animate-spin animate-duration-2000" />
          {{ claimableBalance }}
        </div>

        <v-button
          class="mt-0.32 w-full"
          :loading="isClaim"
          :disabled="disabled" @click="!disabled ? isConfirm = true : null"
        >
          Confirm Claim
        </v-button>
      </div>

      <div v-else>
        <div class="mb-0.08 flex flex-col justify-between gap-y-0.08">
          <div class="mb-0.08 flex justify-between">
            <div class="text-body2 text-grey2">
              Address
            </div>
            <div class="flex items-center gap-x-0.04 text-white1">
              {{ address }}
            </div>
          </div>
          <div class="mb-0.08 flex justify-between">
            <div class="text-body2 text-grey2">
              Network
            </div>
            <div class="flex items-center gap-x-0.04 text-white1">
              <svg-scroll />
              Scroll
            </div>
          </div>
          <div class="mb-0.08 flex justify-between">
            <div class="text-body2 text-grey2">
              Asset
            </div>
            <div class="flex items-center gap-x-0.04 text-white1">
              <VIcon :currency="currentItem.assetName" class="h-0.24 w-0.24" />
              {{ currentItem.assetName }}
            </div>
          </div>
          <div class="mb-0.32 mt-0.24 bg-black3 pt-0.01" />
          <div v-if="!isSuccess" class="flex flex-col items-center justify-center">
            <div class="mb-0.04 text-caption2b text-grey1">
              Claim Amount
            </div>
            <div class="flex items-center gap-x-0.08 text-body1b text-white1">
              <VIcon :currency="currentItem.assetName" class="h-0.24 w-0.24" />
              {{ claimableBalance }}
              {{ currentItem.assetName }}
            </div>
          </div>

          <div v-if="!isSuccess">
            <v-button
              v-if="isClaim"
              class="mt-0.32 w-full"
              :loading="isClaim"
              @click="claim"
            >
              Sign in Wallet
            </v-button>
            <div v-else class="mt-0.32 flex items-center gap-x-0.32">
              <v-button
                v-if="!isClaim" class="flex-1" type="outline"
                @click="isConfirm = false"
              >
                Previous
              </v-button>
              <v-button
                class="flex-1" :loading="isClaim"
                @click="claim"
              >
                Sign in Wallet
              </v-button>
            </div>
          </div>
          <div v-else>
            <v-button
              class="mt-0.32 w-full"
              @click="goBack"
            >
              Back ({{ successSeconds }}s)
            </v-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
