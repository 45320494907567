import type { Chain } from 'viem'
import type { ChainConfig } from '~/service/modules/public'

let chainConfig: ChainConfig[] = []

export async function getChainConfig() {
  try {
    const result = await vesselApiServer.getChains()
    chainConfig = result.data.map(res => ({
      ...res,
      id: Number(res.chainId),
      name: res.name,
      nativeCurrencyDecimals: Number(res.coinOnChainDecimal),
      nativeCurrencyName: res.coinName,
      nativeCurrencySymbol: res.coinSymbol,
      publicRpcUrl: res.publicRpc,
      vaultAddress: res.vesselVaultAddress,
      explorerName: res.scanName,
      explorerUrl: res.scanUrl,
    }))
    return chainConfig
  }
  catch {
    chainConfig = [{
      id: 534352,
      name: 'Scroll',
      nativeCurrencyDecimals: 18,
      nativeCurrencyName: 'Ether',
      nativeCurrencySymbol: 'ETH',
      publicRpcUrl: 'https://rpc.scroll.io',
      vaultAddress: '0x6126E927627b8d9eb9aDb9faadC47B76F94B6bA2',
      explorerName: 'ScrollScan',
      explorerUrl: 'https://scrollscan.com/',
    }]
    return chainConfig
  }
}

export function getChainConfigSync() {
  return chainConfig
}

export function getConfigChains() {
  const chainConfig = getChainConfigSync()
  return chainConfig.map(c => ({
    id: Number(c.id),
    name: c.name,
    nativeCurrency: {
      name: c.nativeCurrencySymbol,
      symbol: c.nativeCurrencySymbol,
      decimals: Number(c.nativeCurrencyDecimals),
    },
    rpcUrls: {
      default: {
        http: [c.publicRpcUrl],
      },
    },
    blockExplorers: {
      default: {
        name: c.explorerName,
        url: c.explorerUrl,
      },
    },
  })) as unknown as readonly [Chain, ...Chain[]]
}
