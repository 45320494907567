<script setup lang="ts">
import BN from 'bignumber.js'
import type { UserPositionItem } from '@/service/modules/amm'

const route = useRoute()
const router = useRouter()

const { poolDetailList, updatePool, selectPool, getUserPosition, selectPoolRow } = useAmm()
const { symbolList, currencyList, tickerInfo } = useSymbol()
const { symbolPrice, assetBalance } = useWs()
const { run: removePool, isLoading: isLoadingAdd } = useHttp(vesselApi.amm.removePool)

const showPreview = ref(false)
const isMinHigherThanMin = ref(false)
const isInsufficient = ref(false)
const isRemoving = ref(false)
const percent = ref(25)

const currencyDirection = ref('')
const lastEditAmount = ref<'base' | 'quote'>('base')

const notification = useNotification()

const form = ref<{
  symbol: string
  min: string
  max: string
  minIndex: number
  maxIndex: number
  baseAmount: string
  quoteAmount: string
}>({
  symbol: '',
  minIndex: 0,
  maxIndex: 0,
  min: '',
  max: '',
  baseAmount: '',
  quoteAmount: '',
})

function back() {
  if (history.state.back) {
    router.back()
  }
  else {
    router.replace('/pools')
  }
}

function handleChangePool() {
  // remove all inputs
  currencyDirection.value = getSymbolItem(form.value.symbol).quote
}

const currencyPair = computed(() => {
  return getSymbolItem(form.value.symbol)
})

const currentPairSwitch = computed(() => {
  if (currencyPair.value?.base) {
    return [currencyPair.value.base, currencyPair.value.quote]
  }
  else {
    return ['base', 'quote']
  }
})

const currentSymbolItem = computed(() => poolDetailList.value.find(i => i.poolId === selectPool.value.poolId))
const currentTickerItem = computed(() => tickerInfo.value.find(i => i.symbol === form.value.symbol))

const isQuote = computed(() => currencyDirection.value === currencyPair.value?.base)
const priceSuffix = computed(() => isQuote.value
  ? `${currencyPair.value?.quote}/${currencyPair.value?.base}`
  : `${currencyPair.value?.base}/${currencyPair.value?.quote}`,
)
const currentPrice = computed(() => {
  return symbolPrice.value?.[form.value.symbol]?.closePrice
})
const currentPriceIndex = computed(() => getSymbolIndex(currentPrice.value))

function getAvailableBalance(assetName: string) {
  return assetBalance.value?.find(i => i.assetName === assetName)?.available || 0
}

function init() {
  if (symbolList.value.length) {
    const symbol = route.query.symbol as string
    if (symbol) {
      form.value.symbol = symbol
    }
    else {
      form.value.symbol = symbolList.value[0].symbol
    }
    currencyDirection.value = getSymbolItem(form.value.symbol)?.base
  }
}

function getSymbolIndex(value: string | number) {
  if (!value) {
    return 0
  }
  const currentSymbolAmmItem = currentSymbolItem.value

  if (+value < +currentSymbolAmmItem?.minTick) {
    return 0
  }
  if (+value > +currentSymbolAmmItem?.maxTick) {
    return Math.round((+currentSymbolAmmItem.maxTick - +currentSymbolAmmItem.minTick) / +currentSymbolAmmItem.tickSpacing)
  }
  return Math.round((+value - +currentSymbolAmmItem?.minTick) / +currentSymbolAmmItem?.tickSpacing)
}

function getSymbolValueFromIndex(index: number) {
  const currentSymbolAmmItem = currentSymbolItem.value
  return BN(currentSymbolAmmItem?.minTick).plus(BN(currentSymbolAmmItem?.tickSpacing).multipliedBy(BN(index))).toString()
}

function getPrice(amount: string, assetName: string) {
  const price = currencyList.value.find(i => i.assetName === assetName)?.price || 0

  return `$${BN(amount || 0).multipliedBy(BN(price)).toFixed(2)}`
}

const minRemovePercent = computed(() => {
  if (!currentTickerItem.value) {
    return 0
  }
  const minBase = currentPriceIndex.value > form.value.minIndex
    ? (form.value.maxIndex - currentPriceIndex.value + 1) / 10 ** +currentTickerItem.value.baseAssetPrecision
    : (form.value.maxIndex - form.value.minIndex + 1) / 10 ** +currentTickerItem.value.baseAssetPrecision
  const minQuote = currentPriceIndex.value < form.value.maxIndex
    ? (currentPriceIndex.value - form.value.minIndex + 1) / 10 ** +currentTickerItem.value.baseAssetPrecision
    * +getSymbolValueFromIndex((+form.value.minIndex + currentPriceIndex.value) / 2)
    : (form.value.maxIndex - form.value.minIndex + 1) * +getSymbolValueFromIndex((+form.value.minIndex + currentPriceIndex.value) / 2) / 10 ** +currentTickerItem.value.baseAssetPrecision
  const baseAmount = selectPool.value.baseAmount
  const quoteAmount = selectPool.value.quoteAmount
  if (!baseAmount || !quoteAmount) {
    return 0
  }
  const p = Math.min(Math.max(minBase / +baseAmount, minQuote / +quoteAmount), 1)
  return p
})

function getSymbolItemByPoolId(poolId: string) {
  const symbol = poolDetailList.value.find(i => i.poolId === poolId)?.symbol
  return symbolList.value.find(i => i.symbol === symbol)
}

function isInRange(row: UserPositionItem) {
  const currentPrice = +symbolPrice.value?.[getSymbolItemByPoolId(row.poolId)?.symbol]?.closePrice
  return +row.tickL < currentPrice && currentPrice < +row.tickR
}

function onPreview() {
  if (percent.value === 0 || percent.value < minRemovePercent.value * 100) {
    return
  }
  showPreview.value = true
}

function getPrecisionValue(value: number, fixed: number) {
  if (fixed >= 0) {
    return value.toFixed(fixed)
  }
  else {
    return +(+((value / (10 ** -fixed)).toFixed(0)) * (10 ** -fixed)).toFixed(0)
  }
}

async function removeLiquidity() {
  if (isRemoving.value) {
    return
  }
  isRemoving.value = true
  const d = +currentTickerItem.value?.quoteAssetPrecision
  const b = +currentTickerItem.value?.baseAssetPrecision
  // remove
  const signMessage = {
    poolId: +currentSymbolItem.value.poolId,
    addType: 0,
    tickIndexL: form.value.minIndex,
    tickIndexR: form.value.maxIndex,
    baseAmount: +getPrecisionValue(+(+selectPool.value.baseAmount * (percent.value < 100 ? percent.value : 120) / 100), (b)),
    quoteAmount: +getPrecisionValue(+(+selectPool.value.quoteAmount * (percent.value < 100 ? percent.value : 120) / 100), d),
    timestamp: new Date().getTime(),
    // timestamp: 1710195590372,
    // nonce: 72,
    nonce: new Date().getTime(),
  }
  const signature = await signPool(signMessage, form.value.symbol)

  removePool({
    ...signMessage,
    signature,
  }, {
    headers: {
      'VESSEL-TIMESTAMP': signMessage.timestamp,
    },
  }).then((res) => {
    if (res.data.error) {
      return
    }
    notification.success({
      title: 'Remove Liquidity Confirmation',
      content: `Your liquidity of ${Math.abs(res.data.baseAssetAmount)} ${currencyPair.value.base} and ${Math.abs(res.data.quoteAssetAmount)} ${currencyPair.value.quote} has been successfully removed to the ${currencyPair.value.base}/${currencyPair.value.quote} pool.`,
      duration: 2000,
    })
    isRemoving.value = false
    showPreview.value = false
    getUserPosition()
    if (percent.value >= 100) {
      selectPoolRow.value = null
    }
    router.back()
  })
}

const isOutOfRange = computed(() => (form.value.maxIndex && form.value.maxIndex < currentPriceIndex.value)
|| (form.value.minIndex && form.value.minIndex > currentPriceIndex.value))

watch(symbolList, () => {
  init()
})
onMounted(() => {
  if (!selectPool.value) {
    router.replace('/pools')
  }
  else {
    const symbol = poolDetailList.value.find(i => i.poolId === selectPool.value.poolId)?.symbol
    form.value.symbol = symbol
    nextTick(() => {
      form.value = {
        symbol,
        minIndex: getSymbolIndex(selectPool.value.tickL),
        maxIndex: getSymbolIndex(selectPool.value.tickR),
        min: `${selectPool.value.tickL}`,
        max: `${selectPool.value.tickR}`,
        baseAmount: '',
        quoteAmount: '',
      }
    })
  }
  init()
  updatePool()
})
</script>

<template>
  <div v-if="selectPool" class="mx-auto w-6.22">
    <div class="flex cursor-pointer items-center" @click="back">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2071 7.79289C14.5976 8.18342 14.5976 8.81658 14.2071 9.20711L11.4142 12L14.2071 14.7929C14.5976 15.1834 14.5976 15.8166 14.2071 16.2071C13.8166 16.5976 13.1834 16.5976 12.7929 16.2071L9.29289 12.7071C8.90237 12.3166 8.90237 11.6834 9.29289 11.2929L12.7929 7.79289C13.1834 7.40237 13.8166 7.40237 14.2071 7.79289Z" fill="#FCFCFD" />
      </svg>
      Back
    </div>
    <div class="text-0.32 font-700 font-dm">
      Remove Liquidity
    </div>

    <div class="my-0.14 flex items-center justify-between text-0.16 font-700 font-dm">
      <div class="flex items-center gap-x-0.04">
        <v-icon-pair :base="getSymbolItemByPoolId(selectPool.poolId)?.base" :quote="getSymbolItemByPoolId(selectPool.poolId)?.quote" :size="24" />
        <div>
          <span class="text-caption1">{{ getSymbolItemByPoolId(selectPool.poolId)?.base }}/{{ getSymbolItemByPoolId(selectPool.poolId)?.quote }}</span>
          <div class="flex items-center">
            <v-tick-spacing :tick-spacing="currentSymbolItem.tickSpacing" :token="getSymbolItemByPoolId(selectPool.poolId)?.base " />
            <v-in-range :is-in="isInRange(selectPool)" />
          </div>
        </div>
      </div>
    </div>

    <div class="z-1 mt-0.12">
      <v-progress v-model="percent" class="flex" />
    </div>

    <div class="flex justify-between">
      <div class="text-0.24 font-600">
        {{ percent }}%
      </div>
      <div class="flex items-center gap-x-0.12">
        <div
          v-for="i in 3" :key="i"
          class="cursor-pointer rd-0.16 bg-black3 px-0.12 py-0.04 text-0.12 text-white2 font-700 hover:text-primary"
          @click="percent = 25 * i"
        >
          {{ 25 * i }}%
        </div>
        <div
          class="cursor-pointer rd-0.16 bg-black3 px-0.12 py-0.04 text-0.12 text-white2 font-700 hover:text-primary"
          @click="percent = 100"
        >
          Max
        </div>
      </div>
    </div>

    <div v-if="percent < minRemovePercent * 100" class="mt-0.12 text-caption1b text-red">
      You must at least remove {{ getRatio(Math.min(1, minRemovePercent + 0.005), 0) }} from the current position.
    </div>

    <div class="mt-0.14 text-grey2">
      You'll receive
    </div>
    <div class="mt-0.12 rd-0.12 bg-black3 p-0.12">
      <div class="flex justify-between text-white2">
        <div class="flex items-center gap-x-0.08 text-0.14 font-500">
          <v-icon :currency="getSymbolItemByPoolId(selectPool.poolId)?.base" class="w-0.24" />
          {{ getSymbolItemByPoolId(selectPool.poolId)?.base }}
        </div>
        <div class="mr-0.04 text-white2">
          {{ formatNumber(+(+selectPool.baseAmount * percent / 100), +currentTickerItem?.baseAssetPrecision) }}
        </div>
      </div>
      <div class="mt-0.24 flex justify-between text-white2">
        <div class="flex items-center gap-x-0.08 text-0.14 font-500">
          <v-icon :currency="getSymbolItemByPoolId(selectPool.poolId)?.quote" class="w-0.24" />
          {{ getSymbolItemByPoolId(selectPool.poolId)?.quote }}
        </div>
        <div class="mr-0.04 text-white2">
          {{ formatNumber(+(+selectPool.quoteAmount * percent / 100), +currentTickerItem?.quoteAssetPrecision - +currentTickerItem?.baseAssetPrecision) }}
        </div>
      </div>
    </div>

    <div class="mt-0.14 text-grey2">
      You'll receive collected fees
    </div>
    <div class="mt-0.12 rd-0.12 bg-black3 p-0.12">
      <div class="flex justify-between text-white2">
        <div class="flex items-center gap-x-0.08 text-0.14 font-500">
          <v-icon :currency="getSymbolItemByPoolId(selectPool.poolId)?.base" class="w-0.24" />
          {{ getSymbolItemByPoolId(selectPool.poolId)?.base }}
        </div>
        <div>
          <span class="mr-0.04 text-white2">{{ formatNumber(selectPool.baseFeeAmount, -1) }}</span>
        </div>
      </div>
      <div class="mt-0.24 flex justify-between text-white2">
        <div class="flex items-center gap-x-0.08 text-0.14 font-500">
          <v-icon :currency="getSymbolItemByPoolId(selectPool.poolId)?.quote" class="w-0.24" />
          {{ getSymbolItemByPoolId(selectPool.poolId)?.quote }}
        </div>
        <div>
          <span class="mr-0.04 text-white2">{{ formatNumber(selectPool.quoteFeeAmount, -1) }}</span>
        </div>
      </div>
    </div>
    <v-button class="mt-0.32 w-full" type="primary" :disabled="percent === 0 || percent < minRemovePercent * 100" @click="onPreview">
      Preview
    </v-button>

    <v-modal
      v-model:show="showPreview"
      modal-class="w-5.5 text-grey1"
      :z-index="201"
      title="Remove Liquidity"
    >
      <div class="mt-0.2 font-500">
        <div class="mt-0.14 text-grey2">
          You'll receive
        </div>
        <div class="mt-0.12 rd-0.12 bg-black3 p-0.12">
          <div class="flex justify-between text-white2">
            <div class="flex items-center gap-x-0.08 text-0.14 font-500">
              <v-icon :currency="getSymbolItemByPoolId(selectPool.poolId)?.base" class="w-0.24" />
              {{ getSymbolItemByPoolId(selectPool.poolId)?.base }}
            </div>
            <div class="mr-0.04 text-white2">
              {{ +(+selectPool.baseAmount * percent / 100).toFixed(+getSymbolItemByPoolId(selectPool.poolId)?.baseOnChainDecimal) }}
            </div>
          </div>
          <div class="mt-0.24 flex justify-between text-white2">
            <div class="flex items-center gap-x-0.08 text-0.14 font-500">
              <v-icon :currency="getSymbolItemByPoolId(selectPool.poolId)?.quote" class="w-0.24" />
              {{ getSymbolItemByPoolId(selectPool.poolId)?.quote }}
            </div>
            <div class="mr-0.04 text-white2">
              {{ +(+selectPool.quoteAmount * percent / 100).toFixed(+getSymbolItemByPoolId(selectPool.poolId)?.quoteOnChainDecimal) }}
            </div>
          </div>
        </div>

        <div class="mt-0.14 text-grey2">
          You'll receive collected fees
        </div>
        <div class="mt-0.12 rd-0.12 bg-black3 p-0.12">
          <div class="flex justify-between text-white2">
            <div class="flex items-center gap-x-0.08 text-0.14 font-500">
              <v-icon :currency="getSymbolItemByPoolId(selectPool.poolId)?.base" class="w-0.24" />
              {{ getSymbolItemByPoolId(selectPool.poolId)?.base }}
            </div>
            <div>
              <span class="mr-0.04 text-white2">{{ selectPool.baseFeeAmount }}</span>
            </div>
          </div>
          <div class="mt-0.24 flex justify-between text-white2">
            <div class="flex items-center gap-x-0.08 text-0.14 font-500">
              <v-icon :currency="getSymbolItemByPoolId(selectPool.poolId)?.quote" class="w-0.24" />
              {{ getSymbolItemByPoolId(selectPool.poolId)?.quote }}
            </div>
            <div>
              <span class="mr-0.04 text-white2">{{ selectPool.quoteFeeAmount }}</span>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="mt-0.32 flex gap-x-0.16">
          <v-button class="flex-1" :loading="isRemoving" @click="removeLiquidity">
            Remove Liquidity
          </v-button>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<style scoped>

</style>
