<script setup lang="tsx">
import { useRouteQuery } from '@vueuse/router'
import { useSignTypedData } from '@wagmi/vue'
import type { SelectOption } from 'naive-ui'
import { hexToBigInt, isAddress } from 'viem'
import VIcon from '@/components/v/Icon.vue'
import type { AssetItem } from '~/service/modules/user'

const { modal, closeModal, openModal } = useModal()

const { signTypedDataAsync } = useSignTypedData()

const { assetBalance: _assetBalance } = useWs()
const { assetList, address } = useUser()
const notification = useNotification()
const { currencyList } = useSymbol()
const { currentChainId, checkChain } = useConnector()
const message = useMessage()

const current = ref()
const amount = ref('')
const toAddress = ref('')
const isPreview = ref(false)
const isTransfer = ref(false)
const isChecking = ref(false)
const isNotRegistered = ref(false)
const assetId = useRouteQuery('assetId')
const symbol = useRouteQuery('symbol')

const assetBalance = computed<AssetItem[]>(() => assetList.value)
const currentItem = computed(() => currencyList.value.find(i => i.assetName === current.value))
const currentBalance = computed(() => assetBalance.value.find(i => i.assetName === current.value))

const { run: callTransfer, isLoading } = useHttp(vesselApi.user.transfer)

function toMaxAmount() {
  amount.value = `${currentBalance.value.available}`
}

function onChangeCurrency() {
  amount.value = ''
}

const accountErrorMsg = computed(() => {
  if (!isChecking.value) {
    return ''
  }
  if (toAddress.value === address.value) {
    return 'Sender and recipient cannot be the same account.'
  }
  if (!isAddress(toAddress.value)) {
    return 'Please enter a valid address.'
  }
  if (isNotRegistered.value) {
    return 'To Address must be registered on Vessel'
  }
  return ''
})

const errorMsg = computed(() => {
  if (!isChecking.value) {
    return ''
  }
  if (+amount.value > +currentBalance.value?.available) {
    return 'Insufficient balance.'
  }
  return ''
})
const disabled = computed(() => !!(!+amount.value || errorMsg.value || accountErrorMsg.value || !toAddress.value))

function goPreview() {
  isChecking.value = true
  if (disabled.value) {
    return
  }
  isPreview.value = true
}

async function transfer() {
  if (isTransfer.value) {
    return
  }
  const isRightChain = await checkChain()
  if (!isRightChain) {
    return
  }
  isTransfer.value = true

  const timestamp = `${new Date().getTime()}`
  // const timestamp = `1729758632915`
  const msgParams = {
    account: address.value.toLowerCase(),
    domain: {
      chainId: currentChainId.value,
      name: 'Vessel',
    },
    message: {
      action: 'Send',
      fromAddress: address.value.toLowerCase(),
      toAddress: toAddress.value.toLowerCase(),
      assetID: currentItem.value.assetId,
      sendAmount: amount.value,
      feeAmount: '0',
      nonce: `${timestamp}`,
      timestamp: `${timestamp}`,
    },
    primaryType: 'Vessel',
    types: {
      EIP712Domain: [
        { name: 'name', type: 'string' },
        { name: 'chainId', type: 'uint256' },
      ],
      Vessel: [
        { name: 'action', type: 'string' },
        { name: 'fromAddress', type: 'address' },
        { name: 'toAddress', type: 'address' },
        { name: 'assetID', type: 'string' },
        { name: 'sendAmount', type: 'string' },
        { name: 'feeAmount', type: 'string' },
        { name: 'nonce', type: 'string' },
        { name: 'timestamp', type: 'string' },
      ],
    },
  }

  const signature = await signTransfer({
    assetId: currentItem.value.assetId,
    assetOnChainDecimal: currentItem.value.onChainDecimal,
    toAddress: toAddress.value.toLowerCase(),
    amount: amount.value,
    fee: '0',
    timestamp,
    // assetId: '0',
    // assetOnChainDecimal: '18',
    // toAddress: '0x90f79bf6eb2c4f870365e785982e1f101e93b906',
    // amount: '123.45',
    // fee: '0',
    // timestamp: '1729144482979',
  })

  signTypedDataAsync(msgParams).then(async (eip712Signature) => {
    console.log(eip712Signature)
    callTransfer({
      signature,
      eip712Signature,
      eip712Message: JSON.stringify(msgParams),
    }, {
      headers: {
        'VESSEL-TIMESTAMP': timestamp,
      },
    }).then((res) => {
      // notification.success({
      //   title: 'Send Confirmation',
      //   content: `Your ${formatNumber(amount.value, -1)} ${currentItem.value.assetName} have been successfully sent to ${toAddress.value}.`,
      //   duration: 2000,
      // })
      isTransfer.value = false
      closeModal('transfer')
    }).catch((res) => {
      isTransfer.value = false
      if (res.code === '14006') {
        isNotRegistered.value = true
        isPreview.value = false
      }
    })
  }).catch(() => {
    isTransfer.value = false
  })
}

function renderLabel(option: SelectOption) {
  return (
    <div class="flex items-center justify-start">
      <VIcon currency={option.assetName as string} class="mr-0.08 h-0.24 w-0.24" />
      {option.assetName}
    </div>
  )
}

whenever(() => modal.value.transfer, () => {
  checkChain()
  isPreview.value = false
  isNotRegistered.value = false
  amount.value = ''
  if (assetId.value) {
    current.value = currencyList.value.find(i => i.assetId === assetId.value)?.assetName
  }
  else if (symbol.value) {
    const pairs = getSymbolItem(symbol.value as string)
    current.value = pairs.baseAssetName
  }
  else if (!current.value) {
    current.value = assetBalance.value[0]?.assetName
  }
})
</script>

<template>
  <v-modal
    v-model:show="modal.transfer"
    modal-class="w-4.84"
    title="Send"
  >
    <div v-if="!isPreview">
      <div class="mb-0.12 mt-0.24 text-body2 text-grey2">
        Select Asset
      </div>
      <n-select
        v-model:value="current"
        :render-label="renderLabel"
        :options="assetBalance"
        filterable
        label-field="assetName" value-field="assetName"
        @update:value="onChangeCurrency"
      />
      <div class="mb-0.12 mt-0.32 text-body2 text-grey2">
        To Address
      </div>
      <v-input
        v-model="toAddress" align="left"
        :error-message="accountErrorMsg"
        @blur="isChecking = true" @input="() => { isNotRegistered = false; isChecking = false }"
      />
      <div class="mb-0.12 mt-0.32 text-body2 text-grey2">
        Amount
      </div>
      <v-input
        v-model="amount" align="left" :error-message="errorMsg"
        :precision="+currentItem?.depositPrecision" @input="errorMsg = ''"
      >
        <template #suffix>
          <v-button type="outline" size="small" @click="toMaxAmount">
            MAX
          </v-button>
        </template>
      </v-input>
      <div class="mt-0.12 flex justify-end gap-x-0.04 text-0.12 text-grey1 font-600">
        <div>Available</div>
        <div class="flex gap-x-0.04 text-white2">
          {{ currentBalance?.available }} {{ current }}
        </div>
      </div>
    </div>
    <div v-else>
      <div>
        <v-alert type="info">
          Please double-check all information before signing, as once it is signed, it cannot be canceled.
        </v-alert>
        <div class="mt-0.4 flex items-center justify-between text-body1b text-white2">
          <div class="flex items-center gap-x-0.04">
            <VIcon :currency="currentItem.assetName" class="mr-0.04 h-0.24 w-0.24" />
            {{ currentItem.assetName }}
          </div>
          <div class="">
            {{ formatNumber(amount, -1) }}
          </div>
        </div>
        <div class="mb-0.12 mt-0.32 text-body2 text-grey2">
          To Address
        </div>
        <div class="text-caption1b text-white2">
          {{ toAddress }}
        </div>
      </div>
    </div>

    <template #footer>
      <v-button
        v-if="!isPreview"
        class="mt-0.32 w-full"
        :disabled="disabled" @click="goPreview"
      >
        Send
      </v-button>
      <div v-else class="mt-0.32 flex items-center gap-x-0.32">
        <v-button
          v-if="!isTransfer" class="flex-1" type="outline"
          @click="isPreview = false"
        >
          Previous
        </v-button>
        <v-button
          class="flex-1" :loading="isTransfer"
          @click="transfer"
        >
          Sign in Wallet
        </v-button>
      </div>
    </template>
  </v-modal>
</template>

<style scoped>
.wallet-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
