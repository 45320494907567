<script setup lang="tsx">
import BN from 'bignumber.js'
import { usePutOrder } from '../limit'
import type { OrderbookItem } from '@/store/ws'
import Login from '~/pages/login.vue'

const props = defineProps<{
  showTitle?: boolean
}>()

interface AggregationItem {
  price: number
  amount: number
  total: number
}

const typeList = [
  {
    value: 0,
    icon: (
      <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="12" height="2" rx="1" fill="#EF445B" />
        <rect y="4" width="12" height="2" rx="1" fill="#B1B5C4" />
        <rect y="8" width="12" height="2" rx="1" fill="#58BD7D" />
      </svg>
    ),
  },
  {
    value: 1,
    icon: (
      <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="12" height="2" rx="1" fill="#B1B5C4" />
        <rect y="4" width="12" height="2" rx="1" fill="#B1B5C4" />
        <rect y="8" width="12" height="2" rx="1" fill="#58BD7D" />
      </svg>
    ),
  },
  {
    value: 2,
    icon: (
      <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="12" height="2" rx="1" fill="#EF445B" />
        <rect y="4" width="12" height="2" rx="1" fill="#B1B5C4" />
        <rect y="8" width="12" height="2" rx="1" fill="#B1B5C4" />
      </svg>
    ),
  },
]
const { currentSymbol } = useSymbol()

const currentSymbolItem = computed(() => getSymbolItem(currentSymbol.value))
const { userInfo } = useUser()
const currentPricePrecision = computed(() => getPrecisionFromSymbol(currentSymbol.value).price)
const currentPrecision = computed(() => getPrecisionFromSymbol(currentSymbol.value))

function toNonExponential(num: string) {
  const m = (+num).toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/)
  if (m) {
    return (+num).toFixed(Math.max(0, (m[1] || '').length - +m[2]))
  }
  return num
}
const aggregationList = computed(() => [
  toNonExponential(BN(10).exponentiatedBy(-1 * currentPricePrecision.value).toString()),
  toNonExponential(BN(10).exponentiatedBy(-1 * currentPricePrecision.value + 1).toString()),
  toNonExponential(BN(10).exponentiatedBy(-1 * currentPricePrecision.value + 2).toString()),
  toNonExponential(BN(10).exponentiatedBy(-1 * currentPricePrecision.value + 3).toString()),
  toNonExponential(BN(10).exponentiatedBy(-1 * currentPricePrecision.value + 4).toString()),
])
const { orderbookList, symbolPrice, tradeList } = useWs()
const currentType = ref(0)
const currentSellIndex = ref(-1)
const currentBuyIndex = ref(-1)
const aggr = ref(toNonExponential(BN(10).exponentiatedBy(-1 * currentPricePrecision.value).toString()))
const currentAccuracy = computed(() => {
  const index = aggregationList.value.findIndex(i => i === aggr.value)
  return Math.max(currentPricePrecision.value - index, 0)
})
const { form, type, isChangePrice } = usePutOrder()

const openOrders = computed(() => userInfo.value.currentOpenOrders.find(i => i.symbol === currentSymbol.value)?.orders.map((i) => {
  const v = BN(i.price).dividedBy(BN(+aggr.value)).toNumber()
  return +((i.side === 'SELL' ? Math.ceil(v) : Math.floor(v)) / (1 / +aggr.value)).toFixed(currentPricePrecision.value)
}) || [])

function formatData(bookList: OrderbookItem[], isSell = false) {
  const result = bookList.reduce<Omit<AggregationItem, 'total'>[]>((res, item) => {
    const price = item.price
    const v = BN(price).dividedBy(BN(+aggr.value)).toNumber()
    const priceLevel = +((isSell ? Math.ceil(v) : Math.floor(v)) / (1 / +aggr.value)).toFixed(currentPricePrecision.value)
    const findItem = res.find(i => i.price === priceLevel)

    if (findItem) {
      findItem.amount += +item.volume
    }
    else {
      res.push({
        amount: +item.volume,
        price: priceLevel,
      })
    }
    return res
  }, [])
  const totalResult = result.sort((i, j) => (i.price - j.price) * (isSell ? 1 : -1)).reduce<AggregationItem[]>(
    (res, i) => ([...res, {
      ...i,
      amount: i.amount,
      total: i.amount + (res.length ? res.at(-1)!.total : 0),
    }]),
    [],
  )
  return totalResult.slice(0, 20)
}

function onMouseEnterOrderbook(index: number, type: 'sell' | 'buy') {
  if (type === 'sell') {
    currentSellIndex.value = index
  }
  else {
    currentBuyIndex.value = index
  }
}

function onMouseLeaveOrderbook() {
  currentSellIndex.value = -1
  currentBuyIndex.value = -1
}

const askList = computed(() => formatData(orderbookList.value.asks, true))
const bidList = computed(() => formatData(orderbookList.value.bids, false))
const maxTotal = computed(() => Math.max(...askList.value.map(i => i.total), ...bidList.value.map(i => i.total)) || 0)

const popoverData = computed(() => {
  if (currentSellIndex.value !== -1) {
    const current = askList.value[currentSellIndex.value]
    const asks = orderbookList.value.asks.filter(i => +i.price <= +current.price)
    const totalQuote = asks.reduce((res, i) => res + +i.price * +i.volume, 0)
    const totalBase = asks.reduce((res, i) => +res + +i.volume, 0)
    const avgPrice = +totalBase > 0 ? totalQuote / +totalBase : 0
    return {
      avg: avgPrice,
      amount: current.amount * current.price,
      total: totalQuote,
    }
  }
  if (currentBuyIndex.value !== -1) {
    const current = bidList.value[currentBuyIndex.value]
    const bids = orderbookList.value.bids.filter(i => +i.price >= +current.price)
    const totalQuote = bids.reduce((res, i) => res + +i.price * +i.volume, 0)
    const totalBase = bids.reduce((res, i) => res + +i.volume, 0)
    const avgPrice = totalBase > 0 ? totalQuote / totalBase : 0
    return {
      avg: avgPrice,
      amount: current.amount * current.price,
      total: totalQuote,
    }
  }
  return {
    avg: 0,
    amount: 0,
    baseAmount: 0,
    total: 0,
  }
})

function handleSyncLimit(item: AggregationItem, selectType: 'sell' | 'buy') {
  const price = item.price
  // type.value = selectType
  form.value[type.value].price = `${price}`
  isChangePrice.value = !isChangePrice.value
}

const currentPrice = computed(() => tradeList.value?.[0]?.price)

watch(currentPricePrecision, () => {
  aggr.value = toNonExponential(BN(10).exponentiatedBy(-1 * currentPricePrecision.value).toString())
})
</script>

<template>
  <div class="flex flex-col" :style="{ '--max': maxTotal }">
    <div v-if="showTitle" class="mt-0.1 text-0.14 font-700 font-dm">
      Order Book
    </div>
    <!-- top bar -->
    <div class="mt-0.04 flex flex-none justify-between px-0.1">
      <div class="flex gap-x-0.12">
        <div
          v-for="item in typeList" :key="item.value"
          class="h-0.32 w-0.32 flex-center cursor-pointer rd-0.04 hover:bg-black2"
          :class="{
            'bg-black2': currentType === item.value,
          }"
          @click="currentType = item.value"
        >
          <Render :value="item.icon" />
        </div>
      </div>
      <div>
        <v-select v-model="aggr" :options="aggregationList" class="w-1" small plain align="right" />
      </div>
    </div>
    <!-- Title -->
    <div class="mt-0.08 flex flex-none px-0.1 text-0.12 text-grey1 font-500">
      <div class="flex-grow-3 flex-basis-none text-left">
        Price({{ currentSymbolItem?.quote }})
      </div>
      <div class="max-w-1.4 flex-grow-4 flex-basis-none truncate text-right">
        Amount({{ currentSymbolItem?.base }})
      </div>
      <div class="max-w-1.1 flex-grow-3 flex-basis-none truncate text-right">
        Total({{ currentSymbolItem?.base }})
      </div>
    </div>
    <div class="h-full flex flex-1 flex-col overflow-hidden px-0.1">
      <!-- Sell List (asks) -->
      <div
        v-if="[0, 2].includes(currentType)"
        class="flex flex-1 flex-col-reverse"
        @mouseleave="onMouseLeaveOrderbook"
      >
        <n-popover
          v-for="(item, index) in askList.slice(0, currentType === 0 ? 10 : 20)" :key="index"
          placement="left" trigger="hover" style="transform: translate(0, -12px)"
        >
          <template #trigger>
            <div
              class="relative h-0.22 p-y-0.02 text-0.12 text-grey1 font-500"
              hover="b-t-1 b-t-dashed"
              :style="{ 'background': currentSellIndex >= index ? 'rgba(53, 57, 69, 0.2)' : 'transparent', '--current': item.amount, '--total': item.total }"
              @mouseenter="onMouseEnterOrderbook(index, 'sell')"
              @click="handleSyncLimit(item, 'sell')"
            >
              <div v-if="openOrders.includes(+item.price)" class="absolute left--0.07 top-0.07">
                <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                  <path d="M0.333984 0L4.33398 2.5L0.333984 5V0Z" fill="#37AAEC" />
                </svg>
              </div>
              <div
                class="list-bg list-bg-sell flex"
              >
                <div class="flex-grow-3 flex-basis-none text-left text-red">
                  {{ formatNumber(item.price, currentAccuracy) }}
                </div>
                <div class="max-w-1.1 flex-grow-4 flex-basis-none text-right">
                  {{ formatNumber(item.amount, currentPrecision.base) }}
                </div>
                <div class="max-w-1.1 flex-grow-3 flex-basis-none text-right">
                  {{ formatNumber(item.total, currentPrecision.base) }}
                </div>
              </div>
            </div>
          </template>
          <div class="w-2.24 flex flex-col gap-y-0.04 px-0.02 py-0.08 text-caption1">
            <div class="flex justify-between">
              <div>
                Avg.Price:
              </div>
              <div>
                ≈{{ formatNumber(popoverData.avg, currentAccuracy) }}
              </div>
            </div>
            <div class="flex justify-between">
              <div>
                Amount({{ currentSymbolItem?.quote }}):
              </div>
              <div>
                {{ formatNumberWithUnit(popoverData.amount, popoverData.amount > 1000 ? 2 : 4) }}
              </div>
            </div>
            <div class="flex justify-between">
              <div>
                Total({{ currentSymbolItem?.quote }}):
              </div>
              <div>
                {{ formatNumberWithUnit(popoverData.total, popoverData.total > 1000 ? 2 : 4) }}
              </div>
            </div>
          </div>
        </n-popover>
      </div>

      <div class="my-0.04 h-0.24 flex-none">
        {{ formatNumber(currentPrice, currentPricePrecision) }}
      </div>

      <!-- Buy List (bids) -->
      <div
        v-if="[0, 1].includes(currentType)"
        class="flex flex-1 flex-col"
        @mouseleave="onMouseLeaveOrderbook"
      >
        <n-popover
          v-for="(item, index) in bidList.slice(0, currentType === 0 ? 10 : 20)" :key="index"
          placement="left" trigger="hover" style="transform: translate(0, 12px)"
        >
          <template #trigger>
            <div
              class="relative h-0.22 p-y-0.02 text-0.12 text-grey1 font-500"
              hover="b-b-1 b-b-dashed"
              :style="{ 'background': currentBuyIndex >= index ? 'rgba(53, 57, 69, 0.2)' : 'transparent', '--current': item.amount, '--total': item.total }"
              @mouseenter="onMouseEnterOrderbook(index, 'buy')"
              @click="handleSyncLimit(item, 'sell')"
            >
              <div v-if="openOrders.includes(item.price)" class="absolute left--0.07 top-0.08">
                <svg xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                  <path d="M0.333984 0L4.33398 2.5L0.333984 5V0Z" fill="#37AAEC" />
                </svg>
              </div>
              <div
                class="list-bg list-bg-buy flex"
              >
                <div class="flex-grow-3 flex-basis-none text-left text-green">
                  {{ formatNumber(item.price, currentAccuracy) }}
                </div>
                <div class="max-w-1.1 flex-grow-4 flex-basis-none text-right">
                  {{ formatNumber(item.amount, currentPrecision.base) }}
                </div>
                <div class="max-w-1.1 flex-grow-3 flex-basis-none text-right">
                  {{ formatNumber(item.total, currentPrecision.base) }}
                </div>
              </div>
            </div>
          </template>
          <div class="w-2.24 flex flex-col gap-y-0.04 px-0.02 py-0.08 text-caption1">
            <div class="flex justify-between">
              <div>
                Avg.Price:
              </div>
              <div>
                ≈{{ formatNumber(popoverData.avg, currentAccuracy) }}
              </div>
            </div>
            <div class="flex justify-between">
              <div>
                Amount({{ currentSymbolItem?.quote }}):
              </div>
              <div>
                {{ formatNumberWithUnit(popoverData.amount, popoverData.amount > 1000 ? 2 : 4) }}
              </div>
            </div>
            <div class="flex justify-between">
              <div>
                Total({{ currentSymbolItem?.quote }}):
              </div>
              <div>
                {{ formatNumberWithUnit(popoverData.total, popoverData.total > 1000 ? 2 : 4) }}
              </div>
            </div>
          </div>
        </n-popover>
      </div>
    </div>
  </div>
</template>

<style scoped>
.list-bg {
  width: 100%;
  --ratio-current: calc(var(--current) / var(--max) * 100%);
  --ratio-total: calc(var(--total) / var(--max) * 100%);
  background: linear-gradient(to left,
  color-mix(in srgb, var(--color-bg), transparent 80%) var(--ratio-current),
  color-mix(in srgb, var(--color-bg), transparent 95%) var(--ratio-current) var(--ratio-total),
  transparent 0%)
}
.list-bg-sell {
  --color-bg: var(--vessel-color-red)
}
.list-bg-buy {
  --color-bg: var(--vessel-color-green)
}
</style>
