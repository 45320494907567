import { ChainNotConfiguredError, createConnector } from '@wagmi/core'
import { type ProviderRpcError, SwitchChainError, UserRejectedRequestError, getAddress, numberToHex } from 'viem'
import type { Chain, ConnectConfig, Provider } from '@particle-network/connect'
import { ParticleConnect, coinbase, metaMask, okx, phantom, trust } from '@particle-network/connect'
import { Ethereum, ScrollSepolia } from '@particle-network/chains'

interface ParticleWagmiWalletParams {
  connectId: string
  walletId: string
  walletName: string
}

export function createParticleWagmiWallet(params?: ParticleWagmiWalletParams) {
    type Properties = any
    const chainId = getConfigChains().at(0)?.id
    // console.log(ScrollSepolia, getConfigChains())

    const config: ConnectConfig = {
      projectId: import.meta.env.VITE_PARTICLE_PROJECT_ID,
      clientKey: import.meta.env.VITE_PARTICLE_CLIENT_KEY,
      appId: import.meta.env.VITE_PARTICLE_APP_ID,
      // chains: chainId === 534351 ? [ScrollSepolia] : getConfigChains() as unknown as Chain[],
      chains: [ScrollSepolia],
      wallets: [
        metaMask({ projectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID }),
        coinbase(),
        okx({ projectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID }),
        trust({ projectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID }),
        phantom(),
      ],
    }
    try {
      const connectKit = new ParticleConnect(config)
      let provider: Provider | null = null
      // const id = connectKit.cachedProviderId()
      // if (id) {
      //   connectKit
      //     .connectToCachedProvider()
      //     .then((_provider) => {
      //       provider = _provider
      //     })
      //     .catch((error) => {
      //       console.error(error)
      //     })
      // }
      return createConnector<Provider, Properties>(config => ({
        id: params.walletId,
        name: params.walletName,
        type: 'particle',
        async connect({ chainId }: { chainId: number }) {
          try {
            provider = await connectKit.connect(params.connectId)
            const accounts = await this.getAccounts()

            provider.on('accountsChanged', this.onAccountsChanged)
            provider.on('chainChanged', this.onChainChanged)
            provider.on('disconnect', this.onDisconnect.bind(this))

            // Switch to chain if provided
            let currentChainId = await this.getChainId()
            if (chainId && currentChainId !== chainId) {
              const chain = await this.switchChain!({ chainId }).catch((error: any) => {
                if (error.code === UserRejectedRequestError.code)
                  throw error
                return { id: currentChainId }
              })
              currentChainId = chain?.id ?? currentChainId
            }

            return { accounts, chainId: currentChainId }
          }
          catch (error: any) {
            if (error.code === 4011)
              throw new UserRejectedRequestError(error as Error)
            throw error
          }
        },
        async disconnect() {
          const provider = await this.getProvider()

          provider.removeListener('accountsChanged', this.onAccountsChanged)
          provider.removeListener('chainChanged', this.onChainChanged)
          provider.removeListener('disconnect', this.onDisconnect.bind(this))

          await (provider as any)?.disconnect?.()
          connectKit.disconnect()
        },
        async getAccounts() {
          const provider = await this.getProvider()
          return (
            await provider.request({
              method: 'eth_accounts',
            })
          ).map((x: string) => getAddress(x))
        },
        async getChainId() {
          const provider = await this.getProvider()
          const chainId = await provider.request({ method: 'eth_chainId' })
          return Number(chainId)
        },
        async getProvider() {
          return provider
        },
        async isAuthorized() {
          try {
            return !!(await this.getAccounts().length)
          }
          catch {
            return false
          }
        },
        async switchChain({ chainId }: { chainId: number }) {
          const chain = config.chains.find(chain => chain.id === chainId)
          if (!chain)
            throw new SwitchChainError(new ChainNotConfiguredError())

          const provider = await this.getProvider()
          const chainId_ = numberToHex(chain.id)

          try {
            await provider.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: chainId_ }],
            })
            return chain
          }
          catch (error) {
            // Indicates chain is not added to provider
            if ((error as ProviderRpcError).code === 4902) {
              try {
                await provider.request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainId: chainId_,
                      chainName: chain.name,
                      nativeCurrency: chain.nativeCurrency,
                      rpcUrls: [chain.rpcUrls.default?.http[0] ?? ''],
                      blockExplorerUrls: [chain.blockExplorers?.default.url],
                    },
                  ],
                })
                return chain
              }
              catch (error) {
                throw new UserRejectedRequestError(error as Error)
              }
            }

            throw new SwitchChainError(error as Error)
          }
        },
        onAccountsChanged(accounts: string[]) {
          if (accounts.length === 0)
            config.emitter.emit('disconnect')
          else
            config.emitter.emit('change', {
              accounts: accounts.map(x => getAddress(x)),
            })
        },
        onChainChanged(chain: string) {
          const chainId = Number(chain)
          config.emitter.emit('change', { chainId })
        },
        async onDisconnect(_error: any) {
          config.emitter.emit('disconnect')

          const provider = await this.getProvider()
          provider.removeListener('accountsChanged', this.onAccountsChanged)
          provider.removeListener('chainChanged', this.onChainChanged)
          provider.removeListener('disconnect', this.onDisconnect.bind(this))
        },
      }))
    }
    catch (e) {
      console.log(e)
      return null
    }
}

export const particleMetamaskWallet = createParticleWagmiWallet({
  connectId: 'metamask',
  walletId: 'particle_metamask',
  walletName: 'Particle Metamask Wallet',
})

export const particleOkxWallet = createParticleWagmiWallet({
  connectId: 'okx',
  walletId: 'particle_okx',
  walletName: 'Particle Okx Wallet',
})

export const particleTrustWallet = createParticleWagmiWallet({
  connectId: 'trust',
  walletId: 'particle_trust',
  walletName: 'Particle Trust Wallet',
})

export const particleCoinbaseWallet = createParticleWagmiWallet({
  connectId: 'coinbase',
  walletId: 'particle_coinbase',
  walletName: 'Particle Coinbase Wallet',
})
