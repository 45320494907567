export async function clearAddressInfo() {
  const { clearUserInfo, initAddress } = useUser()
  const { assetBalance, closeAssetBalanceWs } = useWs()
  const { currentOpenOrderList } = useOrder()
  const { clearKey } = useKey()
  const { closeAllModal } = useModal()
  clearUserInfo()
  clearKey()
  closeAssetBalanceWs()
  currentOpenOrderList.value = []
  assetBalance.value = []
  initAddress.value = ''
  // closeAllModal()
}

export async function disconnectWallet() {
  const { initAddress } = useUser()
  const connector = useConnector()
  const { clearKey } = useKey()
  clearKey(initAddress.value)
  await connector.disconnect()
  clearAddressInfo()
}

export function initNewWallet(address: `0x${string}`) {
  const { initUserInfo, clearUserInfo, userInfo, initAddress, initStatsUserInfo, invitation } = useUser()
  const { initKey } = useKey()
  const { getFaucetInfo } = useFaucet()
  const { subscribeBalance } = useWs()

  if (initAddress.value === address || !address) {
    return
  }
  clearAddressInfo()
  initAddress.value = address

  const isSigned = initKey(address)
  if (isSigned) {
    if (location.pathname === '/login') {
      invitation.value.isValid = true
      location.href = '/trade'
    }
    nextTick(() => {
      initUserInfo().then(() => {
        setTimeout(() => {
          initStatsUserInfo()
        }, 1000)
      })
      getFaucetInfo()
      subscribeBalance()
    })
  }
  else {
    initAddress.value = ''
    setTimeout(() => {
      checkAndBindVesselKey()
    }, 500)
  }
}

export function checkAndBindVesselKey() {
  const { run: verifyAddress } = useHttp(vesselApi.stats.verifyAddress)
  const { address, invitation, initStatsUserInfo } = useUser()
  const { getVesselKey } = useKey()
  const { openModal } = useModal()

  if (!address.value) {
    return
  }

  verifyAddress({
    address: address.value,
  }).then((res) => {
    if (!res.data.isValid) {
      if (!invitation.value.code && location.pathname !== '/login') {
        openModal('walletNotBound')
      }
      else {
        invitation.value.isNewCode = true
        getVesselKey()
      }
    }
    else {
      invitation.value.isNewCode = false
      invitation.value.isValid = true
      invitation.value.code = ''
      if (location.pathname === '/login') {
        location.href = '/trade'
      }
      getVesselKey()
    }
  })
}
