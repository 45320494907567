<script setup lang="ts">
import { use } from 'echarts/core'
import { graphic } from 'echarts'
import type { ECharts, EChartsOption } from 'echarts'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart } from 'echarts/charts'
import BN from 'bignumber.js'
import {
  AxisPointerComponent,
  DataZoomComponent,
  GridComponent,
  MarkAreaComponent,
  MarkLineComponent,
  TooltipComponent,
} from 'echarts/components'
import VChart, { THEME_KEY } from 'vue-echarts'
import { provide, ref } from 'vue'

import type { PoolOverviewItem } from '~/service/modules/amm'
import type { LiquidityItem } from '@/service/modules/amm'

const props = defineProps<{
  data: LiquidityItem[]
  current?: PoolOverviewItem
  currentPrice?: number
  isQuote?: boolean
  decimal?: number
  poolId: string
}>()

const { poolDetailList } = useAmm()
const { tickerInfo, currencyList } = useSymbol()
const myChart = shallowRef<ECharts>(null)
const route = useRoute()

interface ChartData {
  price: number
  amount: number
}

use([
  CanvasRenderer,
  LineChart,
  GridComponent,
  DataZoomComponent,
  AxisPointerComponent,
  TooltipComponent,
  MarkLineComponent,
  MarkAreaComponent,
])

provide(THEME_KEY, 'dark')

const SIZE = 500

const dataZoom = ref({
  start: 0,
  end: 100,
})

const currentPoolItem = computed(() => poolDetailList.value.find(i => i.poolId === props.poolId))
const currentSymbolItem = computed(() => currentPoolItem.value.symbol)
const tickerItem = computed(() => tickerInfo.value.find(i => i.symbol === currentSymbolItem.value))
const basePrecision = computed(() => currencyList.value.find(i => i.assetName === getSymbolItem(currentSymbolItem.value).base)?.depositPrecision)

const splitDataIntegral = computed(() => {
  const res = []
  let total = BN(0)
  for (const i in props.data) {
    const d = props.data[i]
    total = total.plus(BN(d.baseTokenAmount))
    res.push({ price: +d.startTick, amount: total.toNumber() })
    total = total.plus(BN(d.baseTokenAmount).multipliedBy((BN(d.endTick).minus(BN(d.startTick)).dividedBy(BN(currentPoolItem.value.tickSpacing)))))
    res.push({ price: +d.endTick, amount: total.toNumber() })
  }
  return res
})

function getPriceSpot(p: number) {
  const firstIndex = splitDataIntegral.value.findIndex(i => i.price > p) - 1
  if (firstIndex === -2) {
    const item = splitDataIntegral.value.at(-1)
    return item?.amount
  }
  if (firstIndex === -1 || firstIndex === splitDataIntegral.value.length - 1 || splitDataIntegral.value[firstIndex]?.price > p || splitDataIntegral.value[firstIndex + 1]?.price < p) {
    return 0
  }
  const { price: p1, amount: a1 } = splitDataIntegral.value[firstIndex]
  const { price: p2, amount: a2 } = splitDataIntegral.value[firstIndex + 1]
  return (p - p1) / (p1 - p2) * (a1 - a2) + a1
}

// function getPriceData(min: number, max: number) {
//   const filteredDataList = splitDataList.value.filter(i => i.price >= min && i.price < max)
//   return {
//     price: (min + max) / 2,
//     amount: filteredDataList.reduce((res, i) => res + i.amount, 0),
//   }
// }

const tickPriceInChart = computed(() => {
  if (!currentPoolItem.value) {
    return 0
  }
  const minPrice = +props.data?.[0]?.startTick || 0
  const maxPrice = +props.data.at(-1)?.endTick || 0
  const tickNum = (maxPrice - minPrice) / +currentPoolItem.value.tickSpacing / SIZE
  return +tickNum * +currentPoolItem.value.tickSpacing
})

const _data = computed(() => {
  if (!currentPoolItem.value) {
    return []
  }
  const minPrice = +props.data?.[0]?.startTick || 0
  const maxPrice = +props.data.at(-1)?.endTick || 0
  let tickS = (maxPrice - minPrice) / (SIZE + 1)
  let size = SIZE
  if (tickS < +currentPoolItem.value.tickSpacing) {
    tickS = +currentPoolItem.value.tickSpacing
    size = Math.ceil((maxPrice - minPrice) / tickS)
  }

  // const realMinPrice = (minPrice + maxPrice) / 2 - tickNum * +currentPoolItem.value.tickSpacing * SIZE / 2
  return [...Array(size)].map((i, index) => ({ price: minPrice + index * tickS, amount: getPriceSpot(minPrice + (index + 1) * tickS) - getPriceSpot(minPrice + index * tickS) }))
})

function getAmountFromPrice(price: number) {
  return _data.value.find(i => Math.abs(i.price - price) <= +currentPoolItem.value.tickSpacing)?.amount
}

const option = computed<EChartsOption>(() => {
  const isQuote = props.isQuote
  // console.log(isQuote)
  // console.log(_data.value.reverse().map(i => [i.price, i.amount]))

  return {
    backgroundColor: 'transparent',
    animation: false,
    axisPointer: {
      lineStyle: {
        type: [2, 6],
        color: '#E4D7CF',
      },
    },
    grid: {
      top: 20,
      bottom: 30,
      left: 30,
      right: 30,
    },
    tooltip: {
      show: true,
      trigger: 'axis',
      renderMode: 'html',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      padding: 0,
      formatter: (param) => {
        const c = Array.isArray(param) ? param[0] : param
        // return `${c.name} ${c.value}`
        const toolTipWidth = 251
        const toolTipHeight = 120
        // Create and style the tooltip html element
        const toolTip = document.createElement('div')
        // @ts-expect-error will error
        toolTip.style = `width: ${toolTipWidth}px; height: ${toolTipHeight}px;
     position: absolute; display: none; padding: 12px; box-sizing: border-box; font-size: 12px;
     text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none;
     border-radius: 8px;font-family: Poppins; paddding: 6px 12px; font-weight: 500;
     -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`
        toolTip.style.background = 'rgba(35, 38, 47, 0.80)'
        toolTip.style.color = '#E6E8EC'
        toolTip.style.display = 'block'
        const cp = props.currentPrice
        // @ts-expect-error will error
        const price = c.value[0] as number
        // @ts-expect-error will error
        const amount = c.value[1] as number
        const symbolItem = getSymbolItem(currentSymbolItem.value)
        const base = symbolItem.base
        const quote = symbolItem.quote

        if (c && price) {
          toolTip.innerHTML
            = `<div style="color: ${'#777E90'}">Tick Stats</div>
      <div style="font-size: 12px; margin: 4px 0px;display: flex;" class="justify-between">
         <div>${base} Price</div>   <div>${formatNumber(price, +tickerItem.value.quoteAssetPrecision - +tickerItem.value.baseAssetPrecision)} ${quote}</div>
      </div>
      <div style="font-size: 12px; margin: 4px 0px;display: flex;" class="justify-between">
         <div>${quote} Price</div>   <div>${formatNumber(1 / price, props.decimal)} ${base}</div>
      </div>
      <div style="font-size: 12px; margin: 4px 0px;display: flex;" class="justify-between">
         <div>${price > cp ? base : quote} Locked</div>
         ${price > cp ? formatNumber(amount, Math.max(0, +tickerItem.value.baseAssetPrecision)) : formatNumberWithUnit(amount * price, 2)}
      </div>
      `
        }
        return toolTip
      },
    },

    xAxis:
    {
      type: 'value',
      splitLine: { show: false },
      splitNumber: 3,
      min: 'dataMin',
      max: 'dataMax',
      inverse: !isQuote,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        formatter: params => isQuote ? formatNumber(params, 2) : formatNumber(1 / params, props.decimal),
        fontSize: 10,
        color: '#777E90',
      },
    },
    yAxis:
    {
      min: 0,
      // max: value => value / 5,
      type: 'value',
      scale: true,
      splitNumber: 3,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
    },
    dataZoom: [
      {
        type: 'inside',
        xAxisIndex: [0, 1],
        filterMode: 'filter',
        // minSpan: 10,
        // @ts-expect-error will error
        start: myChart.value?.getOption()?.dataZoom?.[0]?.start || 0,
        // @ts-expect-error will error
        end: myChart.value?.getOption()?.dataZoom?.[0]?.end || 100,
      },
    ],
    series: [
      {
        type: 'line',
        smooth: true,
        symbol: 'none',
        data: _data.value.map(i => [i.price, i.amount]),
        barCategoryGap: 0,
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(55, 170, 236)',
            },
            {
              offset: 1,
              color: 'rgb(0, 96, 151)',
            },
          ]),
        },
        lineStyle: {
          color: 'rgb(55, 170, 236)',
          width: 0,
          borderColor: undefined,
          borderColor0: undefined,
        },
        // markArea: {
        //   silent: true,
        //   itemStyle: {
        //     color: '#FFD87C',
        //     opacity: 1,
        //   },
        //   data: [
        //     [
        //       {
        //         yAxis: 0,
        //         xAxis: props.currentPrice - tickPriceInChart.value * 0.3,
        //       },
        //       {
        //         yAxis: getPriceSpot(props.currentPrice + tickPriceInChart.value / 2) - getPriceSpot(props.currentPrice - tickPriceInChart.value / 2),
        //         xAxis: props.currentPrice + tickPriceInChart.value * 0.3,
        //       },

        //     ],
        //   ],
        // },
        markLine: {
          symbol: 'none',
          lineStyle: {
            color: '#FFD87C',
          },
          precision: 8,
          emphasis: {
            disabled: true,
          },
          data: [
            {
              xAxis: props.currentPrice,
              label: {
                show: false,
              },
            },
          ],
        },
      },
    ],
  }
})

watch(option, () => {
  myChart.value.setOption(option.value)
  // setTimeout(() => {
  //   console.log(myChart.value.getOption().series[0].markLine)
  //   console.log(myChart.value.getOption().series[0])
  // }, 100)
})
</script>

<template>
  <VChart ref="myChart" class="h-2 flex-1" :option="option" autoresize />
</template>

<style scoped>

</style>
